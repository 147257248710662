import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';

function AddFaculty() {
    const [teaching, setteaching] = useState(false);
    const [nonteaching, setnonteaching] = useState(true);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


     // integrating post method for College
  const formdata = new FormData();
  const [ftyImg, setftyImg] = useState("");
  const [ftyName, setftyName] = useState("");
  const [ftyDesignation, setftyDesignation] = useState("");
  const [ftyQualification, setftyQualification] = useState("");

  const AddTeaching = async () => {
    formdata.set("ftyImg", ftyImg);
    formdata.set("ftyName", ftyName);
    formdata.set("ftyDesignation", ftyDesignation);
    formdata.set("ftyQualification", ftyQualification);
    try {
      const config = {
        url: "/admin/AddTeaching",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingColleges();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // integrating get method for College

  const [getcollege, setgetcollege] = useState([]);

  const GettingColleges = async () => {
    let res = await axios.get("https://brightbeginning.info/api/admin/listofTeaching");
    if (res.status === 200) {
      setgetcollege(res.data.ListofTeaching);
    }
  };

  useEffect(() => {
    GettingColleges();
  }, []);

  // integrating Delete method

  const [show33, setShow33] = useState(false);
  const [id33, setid33] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setid33(item._id);
  };

  const DeleteTeaching = async () => {
    try {
      const config = {
        url: "/admin/deleteTeaching",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: id33 },
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose33();
          GettingColleges();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  // indetration for Edit

  const [ftyImg1, setftyImg1] = useState("");
  const [ftyName1, setftyName1] = useState("");
  const [ftyDesignation1, setftyDesignation1] = useState("");
  const [ftyQualification1, setftyQualification1] = useState("");



  const data = new FormData();
  const [id, setid] = useState("");
  const [info, setinfo] = useState("");
  const [ftyImg2, setftyImg2] = useState("");
  const [ftyName2, setftyName2] = useState("");
  const [ftyDesignation2, setftyDesignation2] = useState("");
  const [ftyQualification2, setftyQualification2] = useState("");



  
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setid(data._id);
    setinfo(data)
    setftyImg1(data.ftyImg);
    setftyName1(data?.ftyName);
    setftyDesignation1(data?.ftyDesignation);
    setftyQualification1(data?.ftyQualification);

  };

  const editcourse = async () => {
    data.set("CourseId", id);
    data.set("info", info);
    data.set("ftyImg", ftyImg2);
    data.set("ftyName", ftyName2);
    data.set("ftyDesignation", ftyDesignation2);
    data.set("ftyQualification", ftyQualification2);

   
    try {
      const config = {
        url: "/admin/UpdateTeaching",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: data,
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose1();
          GettingColleges();
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };








  // intigration for Nonteaching
       const formdata1 = new FormData();
       const [ntCourseId, setntCourseId] = useState("");
       const [ntinfo, setntinfo] = useState("");
       const [ntImg, setntImg] = useState("");
       const [ntName, setntName] = useState("");
       const [ntDesignation, setntDesignation] = useState("");
       const [ntQualification, setntQualification] = useState("");
     
       const AddNonteaching = async () => {
         formdata.set("ntImg", ntImg);
         formdata.set("ntName", ntName);
         formdata.set("ntDesignation", ntDesignation);
         formdata.set("ntQualification", ntQualification);
         try {
           const config = {

             url: "/admin/AddNonteaching",
             method: "post",
             baseURL: "https://brightbeginning.info/api",
             headers: { "content-type": "multipart/form-data" },
             data: formdata,
           };
           await axios(config).then(function(res) {
             if (res.status === 200) {
               alert(res.data.success);
               handleClose2();
               Gettingfaculty();
               ntImg("");
               ntName("");
               ntDesignation("");
               ntQualification("");
             } else {
               alert("error in adding");
             }
           });
         } catch (error) {
           alert(error.response.data.msg);
         }
       };
      //  get method for nonteaching
  const [getfaculty, setgetfaculty] = useState([]);

  const Gettingfaculty = async () => {
    let res = await axios.get("https://brightbeginning.info/api/admin/listofNonteaching");
    if (res.status === 200) {
      setgetfaculty(res.data.ListofNonteaching);
    }
  };

  useEffect(() => {
    Gettingfaculty();
  }, []);

  // delete method for nonteaching

   const [showdelete, setShowdelete] = useState(false);
   const [iddelete, setiddelete] = useState("");
 
   const handleClosedelete = () => setShowdelete(false);
   const handleShowdelete = (data1) => {
     setShowdelete(true);
     setiddelete(data1._id);
   };
 
   const DeleteNonteaching = async () => {
     try {
       const config = {
         url: "/admin/deleteNonteaching",
         method: "delete",
         baseURL: "https://brightbeginning.info/api",
         header: { "content-type": "application/json" },
         data: { CourseId: iddelete },
       };
       await axios(config).then(function(res) {
         if (res.status === 200) {
           console.log(res.data);
           alert(res.data.success);
           handleClosedelete();
           Gettingfaculty();
         }
       });
     } catch (error) {
       alert(error);
     }
   };

     // indetration for Edit
  const [id1, setid1] = useState("");
  const [info1, setinfo1] = useState("");
  const [ntImg1, setntImg1] = useState("");
  const [ntName1, setntName1] = useState("");
  const [ntDesignation1, setntDesignation1] = useState("");
  const [ntQualification1, setntQualification1] = useState("");



  const data2 = new FormData();
  const [id2, setid2] = useState("");
  const [info2, setinfo2] = useState("");
  const [ntImg2, setntImg2] = useState("");
  const [ntName2, setntName2] = useState("");
  const [ntDesignation2, setntDesignation2] = useState("");
  const [ntQualification2, setntQualification2] = useState("");



  
  const [show111, setShow111] = useState(false);
  const handleClose111 = () => setShow111(false);
  const handleShow111 = (data1) => {
    setShow111(true);
    setid1(data1._id);
    setinfo1(data1)
    setntImg1(data1.ntImg);
    setntName1(data1?.ntName);
    setntDesignation1(data1?.ntDesignation);
    setntQualification1(data1?.ntQualification);

  };

  const editfaculty = async () => {
    data2.set("CourseId", id1);
    data2.set("ntName", info1);
    data2.set("ntImg", ntImg2);
    data2.set("ntName", ntName2);
    data2.set("ntDesignation", ntDesignation2);
    data2.set("ntQualification", ntQualification2);
    
    try {
      const config = {
        url: "/admin/UpdateNonteaching",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: data2,
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose111();
          Gettingfaculty();
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };
 

  return (
    <>
    <section className='d-flex'>
    <Button  style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}  
        onClick={()=>{setteaching(true); setnonteaching(false);}}>
       Teaching
      </Button>
      <Button  style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}  onClick={()=>{setteaching(false); setnonteaching(true);}}>
       Non Teaching
      </Button>
    </section>
    <br/>
    <br/>


{teaching ? (<>
    <section className='container teaching'>
      <div className='d-flex'>
        <h3>Teaching Faculty</h3>
        <Button variant='outline-warning' style={{marginLeft:"10px"}} onClick={handleShow}>Add</Button>
        </div>
        <br/>
    <Table striped bordered hover>
      <thead>
   
        <tr>
          <th>Sl No</th>
          <th> Photo</th>
          <th> Name</th>
          <th>Qualification</th>
          <th>Designation</th>
          <th>Action</th>
        </tr>
      
      </thead>
      <tbody>
      {getcollege.map((data, index) => {
            return ( 
        <tr>
          <td>{++index}</td>
          <td>
              <img className="coll-image" src={`https://brightbeginning.info/Faculty/${data?.ftyImg}`} /></td>
          <td>{data.ftyName}</td>
          <td>{data.ftyQualification}</td>
          <td>{data.ftyDesignation}</td>
          <td>
 
            <Button variant='danger mx-2' onClick={() => handleShow33(data)}>Delete</Button>
            <Button variant="success" onClick={() => {handleShow1(data)}}>Edit</Button>
            </td>
           
        </tr>
          )})}
      </tbody>
    </Table>
    </section>

      {/* modal */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Teaching Faculty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <br />
          <Form.Label style={{ fontWeight: "600", marginTop: "-30px" }}>Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e)=>{setftyImg2(e.target.files[0])}}
          />
          <br />
          <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.ftyName}
            onChange={(e) => setftyName2(e.target.value)}
          />
          <br />
            <br />
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Qualification :</Form.Label>
            <Form.Control
              type="text"
              placeholder={info.ftyQualification}
              onChange={(e) => {
                setftyQualification2(e.target.value);
              }}
            />
            <br />
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Designation :</Form.Label>
            <Form.Control
              type="text"
              placeholder={info.ftyDesignation}
              onChange={(e) => {
                setftyDesignation2(e.target.value);
              }}
            />
          <Button variant="primary" onClick={() => editcourse()}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
</>):(<>
{nonteaching ? (<>
    <section className='container nonteaching'>
    <div className='d-flex'>
    <h3>Non Teaching Faculty</h3>
        <Button variant='outline-warning' style={{marginLeft:"10px"}} onClick={handleShow2}>Add</Button>
        </div>
        <br/>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Sl No</th>
          <th> Photo</th>
          <th> Name</th>
          <th>Qualification</th>
          <th>Designation</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {getfaculty.map((data1, index) => {
            return ( 
        <tr>
          <td>{++index}</td>
          <td>
              <img className="coll-image" src={`https://brightbeginning.info/Faculty/${data1?.ntImg}`} /></td>
          <td>{data1.ntName}</td>
          <td>{data1.ntQualification}</td>
          <td>{data1.ntDesignation}</td>
          <td>
        
            <Button variant='danger mx-2' onClick={() => handleShowdelete(data1)}>Delete</Button>
            <Button variant="success " onClick={() => {handleShow111(data1)}}>Edit</Button>
            </td>
        </tr>
          )})}
      </tbody>
    </Table>
    </section>

    <Modal show={show111} onHide={handleClose111}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Non Teaching Faculty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <br />
          <Form.Label style={{ fontWeight: "600", marginTop: "-30px" }}>Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e)=>{setntImg2(e.target.files[0])}}
          />
          <br />
          <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder={info1.ntName}
            onChange={(e) => setntName2(e.target.value)}
          />
          <br /> 
            <br />
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Qualification :</Form.Label>
            <Form.Control
              type="text"
              placeholder={info1.ntQualification}
              onChange={(e) => {
                setntQualification2(e.target.value);
              }}
            />
            <br />
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Designation :</Form.Label>
            <Form.Control
              type="text"
              placeholder={info1.ntDesignation}
              onChange={(e) => {
                setntDesignation2(e.target.value);
              }}
            />
          <Button variant="primary" onClick={() => editfaculty()}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>

</>):(<></>)}
</>)}
 

   {/* modal teaching */}
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Teaching Faculty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <Form.Control
        type="file"
        accept='image*/'
        onChange={(e)=> {setftyImg(e.target.files[0])}}
      />
      <br/>
      <Form.Label style={{ fontWeight: "600", marginTop: "-30px" }}>Name</Form.Label>
      <Form.Control
        type="text"
        onChange={(e)=>setftyName(e.target.value)}
      />
      <br/>
      <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Qualification</Form.Label>
      <Form.Control
        type="text"
        onChange={(e)=>setftyQualification(e.target.value)}
      />
      <br/>
      <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Designation</Form.Label>
      <Form.Control
        type="text"
        onChange={(e)=> setftyDesignation(e.target.value)}
      />
      <br/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={AddTeaching}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal nonteaching */}
<Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Add Non Teaching Faculty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <Form.Label style={{ fontWeight: "600", marginTop: "-23px" }}>Image</Form.Label>
      <Form.Control
        type="file"
        accept='image*/'
        onClick={(e)=> {setntImg(e.target.files[0])}}
      />
      <br/>
      <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Name</Form.Label>
      <Form.Control
        type="text"
        onChange={(e)=> {setntName(e.target.value)}}
      />
      <br/>
      <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Qualification</Form.Label>
      <Form.Control
        type="text"
        onChange={(e)=> {setntQualification(e.target.value)}}
      />
      <br/>
      <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Designation</Form.Label>
      <Form.Control
        type="text"
        onChange={(e)=> {setntDesignation(e.target.value)}}
      />
      <br/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="danger" onClick={AddNonteaching}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

        {/* delete modal */}
        <Modal show={show33} onHide={handleClose33}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Course</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              DeleteTeaching();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

         {/* delete modal Nonteaching*/}
         <Modal show={showdelete} onHide={handleClosedelete}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Course</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              DeleteNonteaching();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddFaculty