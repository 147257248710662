import React from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import "../Styles/feature.css";
import { TbUsers } from "react-icons/tb";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { SiKnowledgebase } from "react-icons/si";
import { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Feature() {
  // integrating get method for Feature
  const [getFeature, setgetFeature] = useState([]);
  const GettingFeature = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofFeature"
    );
    if (res.status === 200) {
      setgetFeature(res.data.ListofFeature);
    }
  };
  useEffect(() => {
    GettingFeature();
  }, []);

  const [show, setShow] = useState(false);
  const [data1, setdata1] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {setShow(true);setdata1(data)};

  // const[over,setover]=useState("data.FeatureDis")
  return (
    <>
      <div className="affect atf-section-padding">
        <Container>
          <div className="feat_h">
            <div className="atf-section-title mb-5">
              <h2 className="atf-title-color border-left ps-3">
                Our <span>Feature</span>
              </h2>
            </div>
            <Row>
              {getFeature.map((data, index) => {
                return (
                  <Col lg={4} md={4} className="mb-3">
                    <div
                      className="atf-single-feature "
                      style={{ padding: "11px", height: "332px" }}
                    >
                      <TbUsers
                        style={{
                          fontSize: "40px",
                          fontWeight: "bold",
                          color: "#373187",
                          paddingBottom: "10px",
                        }}
                      />
                      <h3>{data.FeatureTitle}</h3>
                      <p >
                        {data?.FeatureDis.slice(0, 290)}....
                        <span style={{color:"#373187"}} onClick={()=>handleShow(data)}>View more</span>
                       
                      </p>
                      <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>  <h3>{data.FeatureTitle}</h3></Modal.Header>
                          <Modal.Body>
                         {data1?.FeatureDis}
                            
                         </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Feature;
