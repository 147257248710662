import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Counter from "./Counter";
import Feature from "./Feature";
import Faculty from "./Faculty";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function About() {
 

  //  get method for Principal
  const [getPrincipal, setgetPrincipal] = useState([]);
 

  const GettingPrincipal = async () => {
    let res = await axios.get(
      "https://brightbeginning.info/api/admin/listofPrincipal"
    );
    if (res.status === 200) {
      setgetPrincipal(res.data.ListofPrincipal);
    }
  };

  useEffect(() => {
    GettingPrincipal();
  }, []);

  const [show, setShow] = useState(false);
  const [data1, setdata1] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (data) => {setShow(true);setdata1(data)};
 
console.log(data1,"gtehu")
  return (
    <>
      <div className=" atf-section-padding">
        {getPrincipal.map((data, index) => {
          return (
            <Container>
              <Row>
                <Col lg={6} md={6}>
                  <div className="atf-about-img ">
                    <img
                      className="vert- "
                      src={`https://brightbeginning.info/Faculty/${data?.pImg}`}
                      style={{ height: "435px" }}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="atf-about-title mt-md-40 atf-section-title">
                    <h5 className="border-left mb-2 ps-3"></h5>
                    <h2 className="atf-title-color">{data.pTitle}</h2>
                    <p>
                      {data?.pDis.slice(0, 800)}...
                      <span
                        style={{ color: "#373187", fontWeight: "600" ,cursor:"pointer"}}
                        onClick={()=>{handleShow(data)}}
                      >
                        Read More
                      </span>
                    </p>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                      <h2 className="atf-title-color">{data1.pTitle}</h2>
                      </Modal.Header>
                      <Modal.Body>
                        {data1?.pDis}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                       
                      </Modal.Footer>
                    </Modal>
                  </div>
                </Col>
              </Row>
            </Container>
          );
        })}
        <Faculty />
        {/* <Counter/> */}
        <Feature />
      </div>
    </>
  );
}

export default About;
