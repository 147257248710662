import React from 'react'
import { BsCalendarDay, BsChevronCompactRight, BsEyeFill } from 'react-icons/bs';
import "./Blog.css";

function Blogs() {
  return (
    <>
    <div class="latest-area section-padding bg-white blog-page">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="section-title-wrapper">
                                    <div class="section-title">
                                        <h3>Latest News</h3>
                                        <p>There are many variations of passages</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="single-latest-item">
                                    <div class="single-latest-image">
                                        <a href="blog-details.html"><img src="blog/1.webp" alt=""/></a>
                                    </div>
                                    <div class="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div class="single-item-comment-view">
                                           <span><BsCalendarDay class="zmdi zmdi-calendar-check"/>25 jun 2050</span>
                                           <span><BsEyeFill class="zmdi zmdi-eye"/>59</span>
                                           <span><BsChevronCompactRight class="zmdi zmdi-comments"/>19</span>
                                       </div>
                                       <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                       <a href="blog-details.html" class="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="single-latest-item">
                                    <div class="single-latest-image">
                                        <a href="blog-details.html"><img src="blog/2.webp" alt=""/></a>
                                    </div>
                                    <div class="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div class="single-item-comment-view">
                                           <span><BsCalendarDay class="zmdi zmdi-calendar-check"/>25 jun 2050</span>
                                           <span><BsEyeFill class="zmdi zmdi-eye"/>59</span>
                                           <span><BsChevronCompactRight class="zmdi zmdi-comments"/>19</span>
                                       </div>
                                       <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                       <a href="blog-details.html" class="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="single-latest-item">
                                    <div class="single-latest-image">
                                        <a href="blog-details.html"><img src="blog/3.webp" alt=""/></a>
                                    </div>
                                    <div class="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div class="single-item-comment-view">
                                           <span><BsCalendarDay class="zmdi zmdi-calendar-check"/>25 jun 2050</span>
                                           <span><BsEyeFill class="zmdi zmdi-eye"/>59</span>
                                           <span><BsChevronCompactRight class="zmdi zmdi-comments"/>19</span>
                                       </div>
                                       <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                       <a href="blog-details.html" class="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="single-latest-item">
                                    <div class="single-latest-image">
                                        <a href="blog-details.html"><img src="blog/4.webp" alt=""/></a>
                                    </div>
                                    <div class="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div class="single-item-comment-view">
                                           <span><BsCalendarDay class="zmdi zmdi-calendar-check"/>25 jun 2050</span>
                                           <span><BsEyeFill class="zmdi zmdi-eye"/>59</span>
                                           <span><BsChevronCompactRight class="zmdi zmdi-comments"/>19</span>
                                       </div>
                                       <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                       <a href="blog-details.html" class="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="single-latest-item">
                                    <div class="single-latest-image">
                                        <a href="blog-details.html"><img src="blog/5.webp" alt=""/></a>
                                    </div>
                                    <div class="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div class="single-item-comment-view">
                                           <span><BsCalendarDay class="zmdi zmdi-calendar-check"/>25 jun 2050</span>
                                           <span><BsEyeFill class="zmdi zmdi-eye"/>59</span>
                                           <span><BsChevronCompactRight class="zmdi zmdi-comments"/>19</span>
                                       </div>
                                       <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                       <a href="blog-details.html" class="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="single-latest-item">
                                    <div class="single-latest-image">
                                        <a href="blog-details.html"><img src="blog/6.webp" alt=""/></a>
                                    </div>
                                    <div class="single-latest-text">
                                        <h3><a href="blog-details.html">Learn English in ease</a></h3>
                                        <div class="single-item-comment-view">
                                           <span><BsCalendarDay class="zmdi zmdi-calendar-check"/>25 jun 2050</span>
                                           <span><BsEyeFill class="zmdi zmdi-eye"/>59</span>
                                           <span><BsChevronCompactRight class="zmdi zmdi-comments"/>19</span>
                                       </div>
                                       <p>There are many variaons of passages of Lorem Ipsuable, amrn in some by injected humour, </p>
                                       <a href="blog-details.html" class="button-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
    </>
  )
}

export default Blogs