import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";

function AddGallery() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // integrating post method for College
  const formdata = new FormData();
  const [GPhoto, setGPhoto] = useState("");
  const [GTitle, setGTitle] = useState("");

  const AddGallerys = async (e) => {
    e.preventDefault();

    formdata.append("GPhoto", GPhoto);
    formdata.append("GTitle", GTitle);

    try {
      if (!GTitle) {
        return alert("Please Add Tittle");
      }
      if (!GPhoto) {
        return alert("Please Add Photo");
      }
      const config = {
        url: "/admin/AddGallery",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };

      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingGallery();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };
  // integrating get method for Intro
  const [getGallery, setgetGallery] = useState([]);
  const GettingGallery = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/ListofGallery"
    );
    if (res.status === 200) {
      setgetGallery(res.data.ListofGallery);
    }
  };
  useEffect(() => {
    GettingGallery();
  }, []);

  // delete method for Principal

  const [showdelete, setShowdelete] = useState(false);
  const [iddelete, setiddelete] = useState("");

  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (data) => {
    setShowdelete(true);
    setiddelete(data._id);
  };

  const DeleteGallery = async () => {
    try {
      const config = {
        url: "/admin/deleteGallery",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: iddelete },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClosedelete();
          GettingGallery();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  const [edit, setedit] = useState("");
  const EditGallerys = async (e) => {
    e.preventDefault();
    //  alert("hii");
    try {
      if(!GPhoto && !GTitle){
        return alert("Please Provide Atleast One Fields")
      }
      const config = {
        url: "/admin/Updategallery",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          id: edit,
          GPhoto: GPhoto,
          GTitle: GTitle,
        },
      };

      await axios(config).then(function (res) {
        //  alert("ji")
        if (res.status === 200) {
          alert("Successfull Updated");
          handleClose1();
          GettingGallery();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };
  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add
      </Button>
      <br />
      <br />

      <section className="container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Event Title</th>
              <th>Main Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getGallery.map((data, index) => {
              return (
                <tr>
                  <td style={{ textAlign: "left" }}>{++index}</td>
                  <td style={{ textAlign: "left" }}>{data.GTitle}</td>
                  <td style={{ textAlign: "left" }}>
                    <img
                      className="coll-image"
                      src={`https://brightbeginning.info/Gallery/${data?.GPhoto}`}
                    />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <Button
                      variant="danger mx-2"
                      className="block-btn"
                      onClick={() => {
                        handleShowdelete(data);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        handleShow1();
                        setedit(data?._id);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>

      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3>Add Gallery</h3>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "600", marginTop: "-30px" }}>
              Event Title
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setGTitle(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>
              Main Image
            </Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setGPhoto(e.target.files[0])}
            />
          </Form.Group>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={(e) => AddGallerys(e)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <Modal show={showdelete} onHide={handleClosedelete}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              DeleteGallery();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          {" "}
          <h3>Edit Gallery</h3>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "600", marginTop: "-30px" }}>Event Title</Form.Label>
            <Form.Control
              type="text"
              value={GTitle}
              onChange={(e) => setGTitle(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>Main Image</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setGPhoto(e.target.files[0])}
            />
          </Form.Group>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="danger" onClick={EditGallerys}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddGallery;
