import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrReactjs } from "react-icons/gr";
import { FaNetworkWired } from "react-icons/fa";
import {FiClock} from 'react-icons/fi'
import {BsPlayCircle} from 'react-icons/bs'
import { DiJavascript, DiPhotoshop, DiPhp } from "react-icons/di";
import {MdFolder} from "react-icons/md"
import "../Styles/online.css";

function Online() {
  return (
    <div className="atf-section-padding">
      <Container>
        <div className="course_hero-0">
          <Row>
            <Col lg={7} md={7}>
              <div className="atf-section-title">
                <h2 className="atf-title-color border-left ps-3">
                  Most Popular <span>Online</span> Best in Courses
                </h2>
              </div>
            </Col>
            <Col lg={5} md={5}>
              <div className="atf-hero-btn text-end">
                <Link to="" class="atf-main-btn">
                  <span>View All Course</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className="atf-section-padding tab-c">
          <ul className="tabs">
            <li className="hvr-rectangle-out tab-active">
              <FaNetworkWired className="iconnet" />
              <br /> Networking
            </li>
            <li  className="hvr-rectangle-out">
              <GrReactjs className="iconnet" />
              <br /> React
            </li>
            <li  className="hvr-rectangle-out">
              <DiJavascript className="iconnet" />
              <br /> JavaScript
            </li>
            <li  className="hvr-rectangle-out">
              <DiPhp className="iconnet" />
              <br /> PHP
            </li>
            <li  className="hvr-rectangle-out">
              <DiPhotoshop className="iconnet" />
              <br />
              PHOTOGRAPHY
            </li>
          </ul>
          {/* Tab content */}
          <div class="tab_content">
								<div className="tabs_item">
									<div className="row">
										<div className="col-lg-6 col-md-12 col-12">
											<div className="tabs_item_content">
												<h3>Art of Photography</h3>
												<ul className="atf-course-admin">
													<li><span>by</span> : Abdur Halim
													
													</li>
													<li><span>In</span> : Course</li>
												</ul> 
												<p className="pe-lg-3"> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.You can join any course my edugarden.</p>
												<div className="atf-course-time">
													<span><FiClock/> Mon-Fri: 10.00-12.50</span>
												</div> 
												<ul className="atf-course-buying mt-4">
													<li><span><MdFolder/></span> 6 Lecture</li>
													<li><span>$</span>50</li>
												</ul> 
												<div className="atf-hero-btn mt-4">
													<a href="#" className="atf-main-btn">Buy Now</a>
												</div> 
											</div>
										</div>
										
										<div className="col-lg-6 col-md-12 col-12">
											<div className="atf-singl-video">
												<div className="atf-tab-img text-center">
													<img className="tab-img" src="/2.jpg" alt=""/>
												</div>
												<div class="waves-box">
													<a href="#" data-rel="lightcase:myCollection" class="iq-video"> 
													<BsPlayCircle className="i-c-d"/>
													<div class="spinnerbtn"></div>
													</a>
												</div>
											</div>
										</div> 
									</div>
								</div>
							</div>
        </div>
      </Container>
    </div>
  );
}

export default Online;
