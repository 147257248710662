import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import "./Courses.css";
import { Rate } from "antd";
import { MdFavorite, MdSupervisorAccount } from "react-icons/md";
import OwlCarousel from "react-owl-carousel";
import Button from "react-bootstrap/Button";
import axios from "axios";

function Faculty() {
  const [teaching, setteaching] = useState(true);
  const [nonteaching, setnonteaching] = useState(false);

  // integrating get method for College

  const [getcollege, setgetcollege] = useState([]);

  const GettingColleges = async () => {
    let res = await axios.get(
      "https://brightbeginning.info/api/admin/listofTeaching"
    );
    if (res.status === 200) {
      setgetcollege(res.data.ListofTeaching);
    }
  };

  useEffect(() => {
    GettingColleges();
  }, []);

  //  get method for nonteaching
  const [getfaculty, setgetfaculty] = useState([]);

  const Gettingfaculty = async () => {
    let res = await axios.get(
      "https://brightbeginning.info/api/admin/listofNonteaching"
    );
    if (res.status === 200) {
      setgetfaculty(res.data.ListofNonteaching);
    }
  };

  useEffect(() => {
    Gettingfaculty();
  }, []);

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  };

  return (
    <>
      <div class="course-area bg-white">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="feat_h">
                <div class="atf-section-title">
                  <h2 class="atf-title-color border-left ps-3">
                    FACULTY<span> LIST</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="atf-hero-btn mt-4 ">
              <a
                class="atf-main-btn"
                onClick={() => {
                  setteaching(false);
                  setnonteaching(true);
                }}
              >
                <span style={{cursor:"pointer"}}>Teaching</span>
              </a>
            </div>
            <div class="atf-hero-btn mt-4" style={{ marginLeft: "10px" }}>
              <a
                class="atf-main-btn"
                onClick={() => {
                  setteaching(true);
                  setnonteaching(false);
                }}
              >
                <span style={{cursor:"pointer"}}>Non Teaching</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      {teaching ? (
        <>
          <section className="container">
            <section className="row">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                responsive={responsive}
                autoplay={true}
                autoplayHoverPause={true}
                nav={false}
              >
                {getcollege.map((data, index) => {
                  return (
                    <div class="single-latest-item">
                      <div class="single-latest-image">
                     
                          <img
                            className="t-image"
                            src={`https://brightbeginning.info/Faculty/${data?.ftyImg}`}
                            style={{height:"280px"}}
                          />
                      
                      </div>
                      <div class="single-latest-text">
                        <h3>
                          {data.ftyName}
                        </h3>
                        <div class="single-item-comment-view">
                          <p>
                            {data.ftyQualification} <br />
                            {data.ftyDesignation}
                          </p>
                        </div>
                        {/* <a href="#" class="button-default">Read More</a> */}
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </section>
          </section>
        </>
      ) : (
        <>
          {nonteaching ? (
            <>
              <section className="container">
                <section className="row">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    responsive={responsive}
                    autoplay={true}
                    autoplayHoverPause={true}
                    nav={false}
                  >
                    {getfaculty.map((data1, index) => {
                      return (
                        <div class="single-latest-item">
                          <div class="single-latest-image">
                          
                              <img
                                className="t-image"
                                src={`https://brightbeginning.info/Faculty/${data1?.ntImg}`}
                              />
                          
                          </div>
                          <div class="single-latest-text">
                            <h3>
                              {data1.ntName}
                            </h3>
                            <div class="single-item-comment-view">
                              <p>
                                {data1.ntQualification} <br />
                                {data1.ntDesignation}
                              </p>
                            </div>
                         
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </section>
              </section>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default Faculty;
