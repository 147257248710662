import React, { useState } from "react";
import "../Contacts.css";
import { IoIosArrowForward } from "react-icons/io";
import { ImFacebook } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { TiSocialInstagram } from "react-icons/ti";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Login() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [acc, setacc] = useState(false);

  const [Login, setLogin] = useState(true);
  const [Register, setRegister] = useState(false);

  const [Name, setName] = useState("");
  const [Number, setNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [QualificationDetails, setQualificationDetails] = useState("");
  const [Password, setPassword] = useState("");

  function isValidMobile(mobile) {
    mobile = mobile?.trim();
    if (mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
      //&& ! (mobile.match(/0{5,}/))
      return true;
    }
    return false;
  }

  function isValidEmail(emailid) {
    emailid = emailid?.trim();
    if (emailid?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      return true;
    }
    return false;
  }

  function isValidPwd(pwd) {
    if (pwd?.length < 8 || pwd?.length > 15) {
      return false;
    }
    return true;
  }

  //postmailStudent
  const addReg = async () => {
    try {
      if (!Name) {
        return alert("Please Enter Name");
      }
      if (!Number) {
        return alert("Please Enter Mobile Number");
      }
      if (!isValidMobile(Number)) {
        return alert("Invalid mobile number");
      }
      if (!Email) {
        return alert("Please Enter Email Id");
      }
      if (!isValidEmail(Email)) {
        return alert("Invalid your email Id");
      }
      if (!Password) {
        return alert("Please Enter Password");
      }
      if (!isValidPwd(Password)) {
        return alert("Please Enter Password");
      }

      const config = {
        url: "/user/StudentReg",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: {
          Name: Name,
          Number: Number,
          Email: Email,
          Password: Password,
        },
      };
      await axios(config).then((res) => {
        console.log(res);
        alert(res.data.success);
        setEmail("");
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
      alert(error.res.data.error);
    }
  };

  const submitlog = async () => {
    try {
      const config = {
        url: "/user/Studentlog",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "application/json" },
        data: { Email: Email, Password: Password },
      };
      await axios(config).then(function (res) {
        console.log(res.status);
        if (res.status === 200) {
          console.log(res.data);
          alert("login sucessfull");
          sessionStorage.setItem("user", JSON.stringify(res.data.details));
          window.location.assign("/");
        } else {
          alert("Invalid Email or Password");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const sendMail = async () => {
    try {
      const config = {
        url: "/user/sendmailotp",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "application/json" },
        data: { email: Email },
      };
      let res = await axios(config);
      if (res.status == 200) {
        handleShow1();
        handleClose();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [userId, setuserId] = useState("");
  const [otp, setotp] = useState("");
  const verifyotp = async () => {
    if (!otp) {
      return alert("Please Enter Otp");
    }
    try {
      const config = {
        url: "/user/verifyOtpEmail",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "application/json" },
        data: { email: Email, otp: otp },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setacc(!acc);
        setuserId(res.data.success._id);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [passwords, setpasswords] = useState("");
  const [conform, setconform] = useState("");
  const resetpasswords = async () => {
    // e.preventDefault()
    if (!passwords) {
      return alert("Please enter passwords");
    }
    if (!conform) {
      return alert("Please enter conform passwords");
    }
    if (passwords !== conform) {
      return alert("Password did not match");
    }
    try {
      const config = {
        url: "/user/forgotPassword",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { id: userId, password: passwords },
      };
      await axios(config).then((res) => {
        console.log(res);
        alert("Successfully reset your passwords");
        handleClose1();
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      {Login ? (
        <>
          <div class="container mt-3 pt-3">
            <div class="row g-0">
              <div class="col-md-12 col-lg-6">
                <h3 className="about-area" style={{ fontSize: "25px" }}>
                  Provide best <span class="orange-color">education</span>{" "}
                  <span class="orange-color">services</span> for you
                </h3>
                <img className="pencil" src="/pencil.png" />{" "}
              </div>
              <div class="col-md-12 col-lg-6 yellow-bg">
                <div class="contact-form-wrap">
                  <h2 class="contact-title">LOGIN</h2>
                  <form id="contact-form" action="mail.php" method="post">
                    <div class="row">
                      <div class="col-12">
                        <div class="contact-form-style mb-20">
                          <input
                            name="name"
                            placeholder="Email*"
                            type="text"
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="contact-form-style mb-20">
                          <input
                            name="phone"
                            placeholder="Password*"
                            type="password"
                            onClick={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <h6
                        className="forgotpass"
                        style={{ cursor: "pointer" }}
                        onClick={handleShow}
                      >
                        Forgot Password
                      </h6>
                    </div>
                  </form>
                  <p class="form-messege">
                    If You Are New Here Please{" "}
                    <a
                      className="register"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRegister(true);
                        setLogin(false);
                      }}
                    >
                      {" "}
                      Register{" "}
                    </a>
                  </p>
                  <div class="col-md-12">
                    <div class="contact-form-style d-flex">
                      <button
                        class="login-button"
                        type="submit"
                        onClick={submitlog}
                      >
                        <span>Login</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="mt-5 pt-5"></section>
        </>
      ) : (
        <>
          {Register ? (
            <>
              <div class="container mt-3 pt-3">
                <div class="row g-0">
                  <div class="col-md-12 col-lg-6">
                    <h3 className="about-area" style={{ fontSize: "25px" }}>
                      CREATE YOUR <span class="orange-color">ACCOUNT</span>{" "}
                      <span class="orange-color">AND</span> JOIN US !
                    </h3>
                    <div class="waves-box">
                      {/* <img className="circle" src='/circle.png'/> */}
                      <div
                        className="circle"
                        style={{
                          backgroundImage: 'url("/circle.png")',
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <img className="cap" src="/register.png" />
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6 yellow-bg">
                    <div class="contact-form-wrap">
                      <h2 class="contact-title">Register</h2>
                      <form id="contact-form" action="mail.php" method="post">
                        <div class="row">
                          <div class="col-12">
                            <div class="contact-form-style mb-20">
                              <input
                                name="name"
                                placeholder="Name*"
                                type="text"
                                value={Name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="contact-form-style mb-20">
                              <input
                               
                                placeholder="Phone*"
                                type="number"
                                value={Number}
                                onChange={(e) => setNumber(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="contact-form-style mb-20">
                              <input
                               
                                placeholder="example@gmail.com"
                                type="email"
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="contact-form-style mb-20">
                              <input
                                name="password"
                                placeholder="Password*"
                                type="password"
                                value={Password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <p class="form-messege">
                        If You Are Registered Please{" "}
                        <a
                          className="register"
                          onClick={() => {
                            setLogin(true);
                            setRegister(false);
                          }}
                        >
                          {" "}
                          Login{" "}
                        </a>
                      </p>
                      <div class="col-md-12">
                        <div class="contact-form-style d-flex">
                          <button
                            class="login-button"
                            type="submit"
                            onClick={addReg}
                          >
                            <span>SignUp</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="mt-5 pt-5"></section>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      {/*forgot Password modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="contact-form-style mb-20">
            <input
              name="email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            variant="primary"
            onClick={() => {
              sendMail();
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Email */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>New Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="contact-form-style mb-20">
            <input
              name="password"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setotp(e.target.value)}
              type="number"
            />
          </div>
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => {
              verifyotp();
            }}
          >
            Verify OTP
          </Button>
          {/* New Generate Passwords */}
          {acc ? (
            <>
              <div className="">
                <form>
                  <div class="contact-form-style mb-20 pb-2">
                    <input
                      name="password"
                      placeholder="Enter password"
                      type="password"
                      value={passwords}
                      onChange={(e) => setpasswords(e.target.value)}
                    />
                  </div>
                  <div class="contact-form-style mb-20 pb-2">
                    <input
                      name="conform password"
                      placeholder="Enter conform password"
                      type="password"
                      value={conform}
                      onChange={(e) => setconform(e.target.value)}
                    />
                  </div>
                  <div class="contact-form-style mb-20 pb-3">
                    <Button onClick={resetpasswords}>Submit</Button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
