import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Advertisment() {
  const [show, setShow] = useState(false);
  const [category, setcategory] = useState("true");
  const [details, setdetails] = useState("");

  const [adds, setAdds] = useState(false);
  const handleClose = () => setAdds(false);
  const handleShow = () => setAdds(true);

  // all category
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // const [show4, setShow4] = useState(false);
  // const handleClose4 = () => setShow4(false);
  // const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
    
  const [show2, setShow2] = useState(false);
  const [id3, setid3] = useState("");
  
  const handleClose2 = () => setShow4(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setid3(item._id);
  };



     // integrating post method for College

     const formdata = new FormData();
     const [AddsCategory, setAddsCategory] = useState("");
     const [AddsTitle, setAddsTitle] = useState("");
     const [AddsDiscript, setAddsDiscript] = useState("");
     const [AddsLink, setAddsLink] = useState("");
     const [Addsimg, setAddsimg] = useState("");
    
   
     const AddallCourse = async () => {
       formdata.set("AddsCategory", AddsCategory);
       formdata.set("AddsTitle", AddsTitle);
       formdata.set("AddsDiscript", AddsDiscript);
       formdata.set("AddsLink", AddsLink);
       formdata.set("Addsimg", Addsimg);
     
       try {
         const config = {
           url: "/admin/AdvertismentDetails",
           method: "post",
           baseURL: "https://brightbeginning.info/api",
           header: {"content-type" : "multipart/form-data"},
           data: formdata,
         };
         await axios(config).then(function (res) {
           if (res.status === 200) {
             alert(res.data.success);
             handleClose5();
             GettingColleges();
           } else {
             alert("error in adding");
          
           }
         });
       } catch (error) {
         alert(error.response.data.msg);
       }
     };
   
   
   
     // integrating get method for College
   
   const [getcollege, setgetcollege] = useState([]);
   
   const GettingColleges = async () => {
     let res = await axios.get("https://brightbeginning.info/api/admin/listofAdvertismentDetails");
     if (res.status === 200) {
       setgetcollege(res.data.ListofAdvertismentDetails);
     }
   };
   
   useEffect(() => {
       GettingColleges();
   }, []);
   
   
   // integrating Delete method
   
   const [show4, setShow4] = useState(false);
   const [id33, setid33] = useState("");
   
   const handleClose4 = () => setShow4(false);
   const handleShow4 = (item) => {
     setShow4(true);
     setid33(item._id);
   };
   
   
   
   const DeleteCollege = async () => {
       try {
         const config = {
           url: "/admin/deleteAdvertismentDetails",
           method: "delete",
           baseURL: "https://brightbeginning.info/api",
           header: { "content-type": "application/json" },
           data: { AdvertismentDetailsId: id33 },
         };
         await axios(config).then(function(res) {
           if (res.status === 200) {
             console.log(res.data);
             alert(res.data.success);
             handleClose2();
             GettingColleges()
           }
         });
       } catch (error) {
         alert(error);
       }
     }
   
   
   
       // indetration for Edit

       const [Add, setAdd] = useState("");
 
       const [addsedit, setAddsedit] = useState(false);
       const handleClose1 = () => setAddsedit(false);
       const handleShow1 = (item) => {
        setAddsedit(true);
        setid(item._id)
        setAdd(item)


      }

       
       const data =  new FormData();
       const [id, setid] = useState("")
       const [AddsCategory2, setAddsCategory2] = useState("");
       const [AddsTitle2, setAddsTitle2] = useState("");
       const [AddsDiscript2, setAddsDiscript2] = useState("");
       const [AddsLink2, setAddsLink2] = useState("");
       const [Addsimg2, setAddsimg2] = useState("");
   
   
   
     
       const editcourse = async () => {

         data.set("AdvertismentId", id)
         data.set("AddsCategory", AddsCategory2)
         data.set("AddsTitle", AddsTitle2)
         data.set("AddsDiscript", AddsDiscript2)
         data.set("AddsLink", AddsLink2)
         data.set("Addsimg", Addsimg2)
         
         try {
           const config = {
             url: "/admin/UpdateAdvertismentDetails",
             method: "put",
             baseURL: "https://brightbeginning.info/api",
             header: { "content-type": "multipart/formdata" },
             data: data,
           };
           await axios(config).then(function(res) {
             if (res.status === 200) {
               console.log(res.data);
               alert(res.data.success);
               handleClose1();
               GettingColleges()
             }
           });
         } catch (error) {
           alert(error.response.data.msg);
         }
       };
 





  return (
    <>
      <div className="d-flex">
        {/* <Button
          variant="primary"
          onClick={() => {
            setcategory(true);
            setdetails(false);
          }}
        >
          All Adds
        </Button>
        <Button
          variant="primary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            setcategory(false);
            setdetails(true);
          }}
        >
          All Category
        </Button> */}

        <Button
          variant="primary"
          style={{ marginLeft: "10px" }}
          onClick={handleShow5}
        >
          Adds Details
        </Button>
      </div>
      <br />
      {category ? (
        <>
          {/* <Button variant="primary" onClick={handleShow}>
            Add Adds
          </Button> */}
          <br />
          <br />
          <table>
            <thead>
              <th>Sl No</th>
              <th>Category</th>
              <th>Title</th>
              <th>Description</th>
              <th>Link</th>
              <th>Image</th>
              <th>Action</th>
            </thead>
            {getcollege.map((item, index)=>
            <tbody>
              <th>{++index}</th>
              <th>{item.AddsCategory}</th>
              <th>{item.AddsTitle}</th>
              <th>{(item.AddsDiscript).slice(0,20)}..</th>
              <th>
                <a>{item.AddsLink}</a>
              </th>
              <th>
                <img className="coll-img" src={`https://brightbeginning.info/Advertisment/${item.Addsimg}`} />
              </th>
              <th>
                <Button variant="outline-success" onClick={()=>handleShow1(item)}>
                  Edit
                </Button>
                <Button variant="outline-danger" onClick={()=>handleShow4(item)}>
                  Delete
                </Button>
              </th>
            </tbody>
            )}
          </table>
        </>
      ) : (
        <>
          {details ? (
            <>
              <Button variant="primary" onClick={handleShow3}>
                Add Category
              </Button>
              <br />
              <br />
              <table>
                <thead>
                  <th>Sl No</th>
                  <th>Category</th>

                  <th>Action</th>
                </thead>
                <tbody>
                  <th>1</th>
                  <th>Best</th>
                  <th>
                    <Button variant="outline-danger" onClick={handleShow2}>
                      Delete
                    </Button>
                  </th>
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {/* ====================modal======================= */}
      <Modal show={adds} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>All Adds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Category</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(e)=>{}}>
            <option hidden>select option</option>
            <option >Best Colleges</option>
            <option >Upcoming Courses</option>
            <option >Recent Updates</option>
          </Form.Select>
          <br />

          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Title" />
          <br />
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Discription*"
            style={{ height: "100px" }}
          />
          <br />
          <Form.Label>Link</Form.Label>
          <Form.Control type="text" placeholder="Link" />
          <br />
          <Form.Label>Image</Form.Label>
          <Form.Control type="file" name="file" />
          <br />

          <Button variant="primary">Submit</Button>
        </Modal.Body>
      </Modal>
      {/* adds edit */}
      <Modal show={addsedit} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>All Adds Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Category</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(e)=>{setAddsCategory2(e.target.value)}}>
          <option hidden>select option</option>
            <option >Best Colleges</option>
            <option >Upcoming Courses</option>
            <option >Recent Updates</option>
          </Form.Select>
          <br />

          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Title" onChange={(e)=>{setAddsTitle2(e.target.value)}}/>
          <br />
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Discription*"
            style={{ height: "100px" }}
            onChange={(e)=>{setAddsDiscript2(e.target.value)}}
          />
          <br />
          <Form.Label>Link</Form.Label>
          <Form.Control type="text" placeholder="Link" onChange={(e)=>{setAddsLink2(e.target.value)}}/>
          <br />
          <Form.Label>Image</Form.Label>
          <Form.Control type="file" name="file" onChange={(e)=>{setAddsimg2(e.target.files[0])}}/>
          <br />

          <Button variant="primary" onClick={()=>{editcourse()}}>Submit</Button>
        </Modal.Body>
      </Modal>



      {/* delete modal */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClose2}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>




      {/* ====================all category=========================== */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Category</Form.Label>
          <Form.Select aria-label="Default select example">
            <option>Open this select menu</option>
            <option >Medical</option>
            <option >Non-Medical</option>
          </Form.Select>
          <br />
          <Button variant="primary">Submit</Button>
        </Modal.Body>
      </Modal>



      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteCollege}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>



      {/* ============add details============ */}


      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Category</Form.Label>
          <Form.Select aria-label="Default select example" onChange={(e)=>{setAddsCategory(e.target.value)}}>
            <option>select option</option>
            <option >Best Colleges</option>
            <option >Upcoming Courses</option>
            <option >Recent Updates</option>
          </Form.Select>
          <br />

          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Title" onChange={(e)=>{setAddsTitle(e.target.value)}} />
          <br />
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Discription*"
            style={{ height: "100px" }}
            onChange={(e)=>{setAddsDiscript(e.target.value)}}
          />
          <br />
          <Form.Label>Link</Form.Label>
          <Form.Control type="text" placeholder="Link"  onChange={(e)=>{setAddsLink(e.target.value)}}/>
          <br />
          <Form.Label>Image</Form.Label>
          <Form.Control type="file" name="file" onChange={(e)=>{setAddsimg(e.target.files[0])}} />
          <br />

          <Button variant="primary" onClick={()=>{AddallCourse()}}>Submit</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Advertisment;