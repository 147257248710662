import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "../Testimonial/Testimonial.css";

const AddPrincipal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };

  // intigration for Principal
  const formdata = new FormData();
  const [ntCourseId, setntCourseId] = useState("");
  const [ntinfo, setntinfo] = useState("");
  const [pImg, setpImg] = useState("");
  const [pTitle, setpTitle] = useState("");
  const [pDis, setpDis] = useState("");

  const AddPrincipal = async () => {
    formdata.set("pImg", pImg);
    formdata.set("pTitle", pTitle);
    formdata.set("pDis", pDis);
    try {
      if (!pTitle) {
        return alert("Please Add Title");
      }
      if (!pImg) {
        return alert("Please Add Image");
      }
      if (!pDis) {
        return alert("Please Add Description");
      }
      const config = {
        url: "/admin/AddPrincipal",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          pTitle("");
          pImg("");
          pDis("");
          GettingPrincipal();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  //  get method for Principal
  const [getPrincipal, setgetPrincipal] = useState([]);

  const GettingPrincipal = async () => {
    let res = await axios.get(
      "https://brightbeginning.info/api/admin/listofPrincipal"
    );
    if (res.status === 200) {
      setgetPrincipal(res.data.ListofPrincipal);
    }
  };

  useEffect(() => {
    GettingPrincipal();
  }, []);

  // delete method for Principal

  const [showdelete, setShowdelete] = useState(false);
  const [iddelete, setiddelete] = useState("");

  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (data) => {
    setShowdelete(true);
    setiddelete(data._id);
  };

  const DeletePrincipal = async () => {
    try {
      const config = {
        url: "/admin/deletePrincipal",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: iddelete },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClosedelete();
          GettingPrincipal();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  // indetration for Edit
  const [id1, setid1] = useState("");
  const [info1, setinfo1] = useState("");
  const [pImg1, setpImg1] = useState("");
  const [pTitle1, setpTitle1] = useState("");
  const [pDis1, setpDis1] = useState("");

  const data2 = new FormData();
  const [id2, setid2] = useState("");
  const [info2, setinfo2] = useState("");
  const [pImg2, setpImg2] = useState("");
  const [pTitle2, setpTitle2] = useState("");
  const [pDis2, setpDis2] = useState("");

  const [show111, setShow111] = useState(false);
  const handleClose111 = () => setShow111(false);
  const handleShow111 = (data) => {
    setShow111(true);
    setid1(data._id);
    setinfo1(data);
    setpImg1(data.pImg);
    setpTitle1(data?.pTitle);
    setpDis1(data?.pDis);
  };

  const editPrincipal = async () => {
    data2.set("CourseId", id1);
    data2.set("info", info1);
    data2.set("pImg", pImg2);
    data2.set("pTitle", pTitle2);
    data2.set("pDis", pDis2);

    try {
      const config = {
        url: "/admin/UpdatePrincipal",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: data2,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose111();
          GettingPrincipal();
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add Principal
      </Button>

      <br />
      <br />
      <table striped bordered hover>
        <thead>
          <th>Slno</th>
          <th>Photo</th>
          <th>Title</th>
          <th>Description</th>
          <th>Action</th>
        </thead>
        <tbody>
          {getPrincipal.map((data, index) => {
            return (
              <tr>
                <td style={{ textAlign: "left" }}>{++index}</td>
                <td style={{ textAlign: "justify" }}>
                  <img
                    className="coll-image"
                    src={`https://brightbeginning.info/Faculty/${data?.pImg}`}
                    height="80"
                    width="100"
                  />
                </td>
                <td style={{ textAlign: "justify" }}>{data.pTitle}</td>
                <td style={{ textAlign: "justify" }}>
                  <p style={{ height: "180px", overflow: "hidden",overflowY:"auto" }}>
                    {data.pDis}
                  </p>
                </td>
                <td>
                  <Button
                    variant="danger mx-2"
                    onClick={() => {
                      handleShowdelete(data);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => {
                      handleShow111(data);
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
          
        </tbody>
      </table>

      <Modal show={show} onHide={handleClose} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Add Principal</h5>
          </div>
          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Title"
              onChange={(e) => {
                setpTitle(e.target.value);
              }}
            />

            <div
              style={{
                display: "flex",
                padding: "2% 2%",
                justifyContent: "space-between",
                margin: "7% 0% 7% -12%",
              }}
            >
              <div className="upload">
                <span>
                  <p className="graphicslabel">Upload Photo</p>
                  <input
                    type="file"
                    accept="image*/"
                    onChange={(e) => {
                      setpImg(e.target.files[0]);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div style={{ width: "45%", marginBottom: "1%" }}></div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Description </p>
            <textarea
              type="text"
              className="graphicsinput"
              placeholder="Description.."
              onChange={(e) => {
                setpDis(e.target.value);
              }}
            ></textarea>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={handleClose}
              className="graphicsinput1"
              style={{ backgroundColor: "#575366" }}
            >
              Back
            </button>
            <button
              className="graphicsinput1"
              style={{ backgroundColor: "rgb(2 174 239)" }}
              onClick={AddPrincipal}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      {/* edit Principal */}
      <Modal show={show111} onHide={handleClose111}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Principal Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Form.Label style={{fontWeight:"600",marginTop:"-30px"}}>Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setpImg2(e.target.files[0]);
            }}
          />
          <br />

          <br />
          <Form.Label style={{fontWeight:"600",marginTop:"-30px"}}>Title :</Form.Label>
          <Form.Control
            type="text"
            style={{marginBottom:"10px"}}
            placeholder={info1.pTitle}
            onChange={(e) => {
              setpTitle2(e.target.value);
            }}
          />
          <br />
          <Form.Label style={{fontWeight:"600",marginTop:"-30px"}}>Description :</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{marginBottom:"10px"}}
            placeholder={info1.pDis}
            onChange={(e) => {
              setpDis2(e.target.value);
            }}
          />
          <Button variant="primary" onClick={() => editPrincipal()}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>

      {/* delete modal */}
      <Modal show={showdelete} onHide={handleClosedelete}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              DeletePrincipal();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPrincipal;
