import React, { useState } from "react";
import "./Contacts.css";
import { IoIosArrowForward } from "react-icons/io";
import { ImFacebook } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { TiSocialInstagram } from "react-icons/ti";
import axios from "axios";
import { useEffect } from "react";

function Contact() {
  const [FirstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");

  const addReg = async (e) => {
    try {
      const config = {
        url: "/admin/Addfeedback",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: {
          FirstName: FirstName,
          lastName: lastName,
          Email: Email,
          Message: Message,
        },
      };
      await axios(config).then((res) => {
        console.log(res);
        alert(res.data.success);
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };

  // integrating post method for Query
  function isValidMobile(mobile) {
    mobile = mobile?.trim();
    if (mobile?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
      //&& ! (mobile.match(/0{5,}/))
      return true;
    }
    return false;
  }

  function isValidEmail(emailid) {
    emailid = emailid?.trim();
    if (emailid?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      return true;
    }
    return false;
  }


  const obj = new FormData();
  const [QName, setQName] = useState("");
  const [QEmail, setQEmail] = useState("");
  const [QPhone, setQPhone] = useState("");
  const [Qmsg, setQmsg] = useState("");

  const AddQuery = async (e) => {
    e.preventDefault();
    obj.set("QName", QName);
    obj.set("QEmail", QEmail);
    obj.set("QPhone", QPhone);
    obj.set("Qmsg", Qmsg);

    try {
      if (!QName) {
        return alert("Please Enter Name");
      }
      if (!QPhone) {
        return alert("Please Enter Mobile Number");
      }
      if (!isValidMobile(QPhone)) {
        return alert("Invalid Mobile Number");
      }
      if (!QEmail) {
        return alert("Please Enter Email Id");
      }
      if (!isValidEmail(QEmail)) {
        return alert("Invalid Email Id");
      }
      if (!Qmsg) {
        return alert("Please Enter Message");
      }
      const config = {
        url: "/admin/AddQuery",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: { QName: QName, QEmail: QEmail, QPhone: QPhone, Qmsg: Qmsg },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingQuery();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // integrating get method for Intro
  const [getQuery, setgetQuery] = useState([]);
  const GettingQuery = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofQuery"
    );
    if (res.status === 200) {
      setgetQuery(res.data.ListofQuery);
    }
  };
  useEffect(() => {
    GettingQuery();
  }, []);

  const [getFooter, setgetFooter] = useState([]);
  const GettingFooter = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofFooter"
    );
    if (res.status === 200) {
      setgetFooter(res.data.listofFooter);
    }
  };
  useEffect(() => {
    GettingFooter();
  }, []);

  // integrating get method for Intro
  const [getcontact, setgetcontact] = useState([]);
  const GettingContact = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/allcontact"
    );
    if (res.status === 200) {
      setgetcontact(res.data.success);
    }
  };
  useEffect(() => {
    GettingContact();
  }, []);
  return (
    <>
      <section
        className="banner nft"
        style={{
          backgroundImage: 'url("/contact.webp")',
          height: "270px",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <section className="mask">
          <div class="container">
            <div class="row">
              <div class="col-md-12 pt-5">
                <h1 class="text-center text-white ugr">Contact</h1>
                <div class="breadcrumb-bar">
                  <ul class="breadcrumb text-center">
                    <li>Home </li>
                    <IoIosArrowForward className="text-white" />
                    <li>Contact</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div class="container mt-5 pt-5">
        <div class="row g-0">
          <div class="col-md-12 col-lg-6 blue-bg">
            <div class="edubuzz-address">
              <h2 class="contact-title">You Can Contact With Us</h2>

              {getcontact.slice(0, 1).map((item) => {
                return <p>{item.text} </p>;
              })}

              <ul>
                {getcontact.slice(0, 1).map((item) => {
                  return (
                    <li>
                      <i class="fa fa-fax"></i>Address : {item.address}
                    </li>
                  );
                })}

                {getFooter.slice(0, 1).map((data, index) => {
                  return (
                    <li>
                      {" "}
                      <i class="fa fa-phone"></i> Phone : {data.FContact}
                      {/* <a href="#">{data.FContact}</a> */}
                    </li>
                  );
                })}

                <li>
                  <i class="fa fa-envelope-o"></i> Web: brightbegining.in
                </li>
              </ul>
              <div class="contact-social">
                <h3>
                  <strong className="text-white">Also Can Find Us</strong>
                </h3>
                <ul
                  className="all-icon d-flex"
                  style={{ padding: "0px", cursor: "pointer" }}
                >
                  <li>
                    <ImFacebook className="c-icon" />
                  </li>
                  <li>
                    <BsTwitter className="c-icon" />
                  </li>
                  <li>
                    <AiOutlineGooglePlus className="c-icon" />
                  </li>
                  <li>
                    <TiSocialInstagram className="c-icon" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 yellow-bg">
            <div class="contact-form-wrap">
              <h2 class="contact-title">SEND YOUR MESSAGE</h2>
              <form id="contact-form">
                <div class="row">
                  <div class="col-12">
                    <div class="contact-form-style mb-20">
                      <input
                        placeholder="Name*"
                        type="text"
                        onChange={(e) => {
                          setQName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="contact-form-style mb-20">
                      <input
                        placeholder="Phone*"
                        type="text"
                        onChange={(e) => setQPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="contact-form-style mb-20">
                      <input
                        placeholder="Email*"
                        type="text"
                        onChange={(e) => setQEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="contact-form-style">
                      <textarea
                        placeholder="Type your message here.."
                        onChange={(e) => setQmsg(e.target.value)}
                      ></textarea>
                      <button class="login-button" onClick={(e) => AddQuery(e)}>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <section className="mt-5 pt-5"></section>
      {/* <div class="newsletter-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5 col-md-5">
                                    <div class="newsletter-content">
                                        <h3 className='text-white'>SUBSCRIBE</h3>
                                        <h2>TO OUR NEWSLETTER</h2>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <div class="newsletter-form angle">
                                        <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="mc-form footer-newsletter fix">
                                            <div class="subscribe-form">
                                                <input id="mc-email" type="email" autocomplete="off" placeholder="Enter your email here"/>
                                                <button id="mc-submit" type="submit">SUBSCRIBE</button>
                                            </div>    
                                        </form>
    
                                        <div class="mailchimp-alerts text-centre fix pull-right">
                                            <div class="mailchimp-submitting"></div>
                                            <div class="mailchimp-success"></div>
                                            <div class="mailchimp-error"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div> */}
    </>
  );
}

export default Contact;
