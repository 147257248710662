import React,{ useState, useEffect } from "react";
import { FaUserTie } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { GiNetworkBars } from "react-icons/gi";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import axios from "axios";
import "./AdminDashoard.css";

const AdminDashoard = () => {


  const [getReg,setgetReg] = useState([])
  const GettingReg= async()=>{
    const res = await axios.get("https://brightbeginning.info/api/user/allstudent");
    if(res.status === 200){
      setgetReg(res.data.all)
    }
  }
  useEffect(()=>{
    GettingReg()
  },[])

  return (
    <>
     <div className="admindashboard">
        <div
          className="py-3 col-xl-12"
          style={{ minHeight: "100vh", height: "100%", background: "white" }}
        >
          <div className="TabLayout">
            <div className="container">
              <div className="row">
                <div className="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="searchHeadFlex">
                    <div style={{ padding: "2% 1%", width: "25%" }}>
                      <button
                        style={{
                          backgroundColor: "#2d3e50",
                          padding: "10px",
                          color: "white",
                          border: "1px solid white",
                          fontWeight: "600",
                          marginLeft: "100%",
                        }}
                      >
                        ADMIN
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
               
               
                <div className="p-4 col-md-3">
                  <div className="dashboardcard text-center p-4">
                    <div className="dashboardcardcontent">
                      <AiFillEye className="dashboardIcon" />

                      <b style={{ fontSize: "30px", fontWeight: "bold" }}>
                        {getReg.length}
                      </b>

                    </div>
                    <b> Total no of Students</b>
                  </div>
                </div>
               
                
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  )
}

export default AdminDashoard