import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import "./Courses.css";
import { Rate } from "antd";
import { MdFavorite, MdSupervisorAccount } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";

function Course() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [element, setelement] = useState({});
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (data) => {
    // console.log(data,"lklklk");
    setelement(data);

    setShow2(true);
  };
  console.log(element, "hiurw");

  // integrating get method for College

  const [getcollege, setgetcollege] = useState([]);

  const GettingColleges = async () => {
    let res = await axios.get(
      "https://brightbeginning.info/api/admin/listofCourse"
    );
    if (res.status === 200) {
      setgetcollege(res.data.ListofCourse);
    }
  };

  useEffect(() => {
    GettingColleges();
  }, []);

  const [Getdata, setGetdata] = useState({});
  const handleClose1 = () => setShow(false);
  const handleShow1 = (ele) => {
    setGetdata(ele.CourseName);
  };
  console.log(Getdata);

  const [GetCollegeCat, setGetCollegeCat] = useState();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // integrating get method for Co
  const [getCo, setgetCo] = useState([]);
  const GettingCo = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofCo"
    );
    if (res.status === 200) {
      setgetCo(res.data.ListofCo);
    }
  };
  useEffect(() => {
    GettingCo();
  }, []);

  // integrating post method for BookCourse

  const obj = new FormData();
  const [BcName, setBcName] = useState("");
  const [BcEmail, setBcEmail] = useState("");
  const [BcPhone, setBcPhone] = useState("");
  const [BcCourse, setBcCourse] = useState("");

  const AddBookCourse = async (item) => {
    obj.set("BcName", BcName);
    obj.set("BcEmail", BcEmail);
    obj.set("BcPhone", BcPhone);
    obj.set("BcCourse", BcCourse);
    if (!user) {
      alert("Please Login");
      window.location.assign("/login");
      return;
    }

    try {
      const config = {
        url: "/admin/AddBookCourse",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          BcName: user?.Name,
          BcEmail: user?.Email,
          BcPhone: user?.Number,
          BcCourse: item?.Course,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          // GettingBookCourse();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <>
      <div class="course-area bg-white mt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="feat_h">
                <div class="atf-section-title ">
                  <h2 class="atf-title-color border-left ps-3">
                    COURSES<span> OFFERED</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            {getcollege.slice(0, 3).map((item, index) => {
              return (
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="single-item mb-50">
                    <div class="single-item-image overlay-effect">
                      <a href="#">
                        <img
                          src={`https://brightbeginning.info/Course/${item.CoursePhoto}`}
                          alt=""
                          style={{ height: "240px" }}
                        />
                      </a>
                      <div class="courses-hover-info">
                        <div class="courses-hover-action">
                          <div class="courses-hover-thumb">
                            <img
                              className="roundedCircle"
                              src={`https://brightbeginning.info/Course/${item?.ProfessorImage}`}
                              alt="small images"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "50%",
                                padding: "18% 18%",
                              }}
                            />
                          </div>
                          <h4>
                            <a href="#">{item.ProfessorName}</a>
                          </h4>
                          <span class="crs-separator">/</span>
                          <p>{item.ProfessorDesignation}</p>
                        </div>
                      </div>
                    </div>
                    <div class="single-item-text">
                      <h4>
                        <Link
                          to={`/courses/${item._id}`}
                          state={{ item: item }}
                        >
                          {" "}
                          {item.Course}
                        </Link>
                      </h4>
                      <p>{item.CourseDis.slice(0, 35)} ...</p>
                      <div class="single-item-content">
                        <div class="single-item-comment-view">
                          <span>
                            <FaRupeeSign class="zmdi zmdi-accounts" />
                            {item.Fee}
                          </span>
                        </div>
                        <div class="single-item-rating">
                          <a
                            class="button-default"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              AddBookCourse(item);
                            }}
                          >
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <section>
        <div class="course-area bg-white mt-5">
          <div class="container">
            <div class="row">
              {getCo.map((data, index) => {
                return (
                  <div class="col-lg-4 col-md-6 col-12">
                    <div className="again mb-3">
                      <div class="atf-about-title mt-md-40 atf-section-title">
                        <h5
                          class="border-left mb-2 ps-3"
                          style={{
                            fontWeight: "700",
                            color: "#e77e1e",
                            fontSize: "18px",
                          }}
                        >
                          {" "}
                          {data.CoTitle}
                        </h5>
                        <h2 class="atf-title-color"> {data.CoSubTitle}</h2>
                      </div>
                      <p>
                        {" "}
                        {data.CoDis.slice(0, 290)}...{" "}
                        <span
                          style={{
                            color: "#373187",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                          onClick={() => handleShow2(data)}
                        >
                          READ MORE
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h3>Register Now</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="contact-form" action="mail.php" method="post">
            <div class="row">
              <div class="col-12">
                <div class="contact-form-style mb-20">
                  <input name="name" placeholder="Name*" type="text" />
                </div>
              </div>
              <div class="col-12">
                <div class="contact-form-style mb-20">
                  <input name="phone" placeholder="Phone*" type="text" />
                </div>
              </div>
              <div class="col-12">
                <div class="contact-form-style mb-20">
                  <input name="email" placeholder="Email*" type="email" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="contact-form-style">
                  <textarea
                    name="message"
                    placeholder="Type your Course details.."
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <div class="atf-about-title mt-md-40 atf-section-title">
              <h2 class="atf-title-color"> {element?.CoSubTitle}</h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" mb-3">
            <h5
              class="border-left mb-2 ps-3"
              style={{ fontWeight: "700", color: "#e77e1e" }}
            >
              {" "}
              {element?.CoTitle}
            </h5>
            <p> {element?.CoDis}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Course;
