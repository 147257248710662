import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Querylist = () => {

 // integrating get method for Intro
 const [getQuery,setgetQuery] = useState([])
 const GettingQuery= async()=>{
   const res = await axios.get("https://brightbeginning.info/api/admin/listofQuery");
   if(res.status === 200){
       setgetQuery(res.data.listofQuery)
   }
 }
 useEffect(()=>{
   GettingQuery()
 },[])

  return (
    <>
     <button
        style={{
          backgroundColor: "#07267d",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
      >
        Query List
      </button>
      <br />
      <br />

      <table id="table" striped bordered hover size="xl">
        <thead>
          <th id="th">Sl No</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Message</th>
        </thead>
        <tbody>
        {getQuery?.map((data, index) => {
            return ( 
              <tr>
              <td style={{textAlign:"left"}}>
                {++index}
              </td>
              <td style={{textAlign:"left"}}>
              {data.QName}
              </td>
              <td style={{textAlign:"left"}}>
                {data.QPhone}
              </td>
              <td  style={{textAlign:"left"}}>
                {data.QEmail}
              </td>
              <td style={{textAlign:"left"}}>
                {data.Qmsg}
              </td>
              </tr>
          )})}
        </tbody>
      </table>
    </>
  )
}

export default Querylist