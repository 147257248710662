import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { GrUpdate } from "react-icons/gr";

const Addgraphis = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };
  // interating post method in Graphic
  const formdata = new FormData();
  const [GraphicTitle, setGraphicTitle] = useState("");
  const [GraphicSubTitle, setGraphicSubTitle] = useState("");
  const [GraphicDis, setGraphicDis] = useState("");
  const [GraphicImg, setGraphicImg] = useState("");

  const AddGraphic = async () => {
    formdata.append("GraphicTitle", GraphicTitle);
    formdata.append("GraphicSubTitle", GraphicSubTitle);
    formdata.append("GraphicDis", GraphicDis);
    formdata.append("GraphicImg", GraphicImg);
    // console.log("formdata",formdata);

    try {
      if (!GraphicTitle) {
        return alert("Please add Title");
      }
      if (!GraphicSubTitle) {
        return alert("Please add SubTitle");
      }
      if (!GraphicDis) {
        return alert("Please add Description");
      }
      if (!GraphicImg) {
        return alert("Please add Images");
      }
      const config = {
        url: "/admin/AddGraphic",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        GettingGraphic();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  // integrating get method for graphic

  const [getGraphics, setgetGraphics] = useState([]);
  const GettingGraphic = async () => {
    let res = await axios.get(
      "https://brightbeginning.info/api/admin/getGraphic"
    );
    if (res.status === 200) {
      setgetGraphics(res.data.getGraphic);
    }
  };
  useEffect(() => {
    GettingGraphic();
  }, []);

  // integrating Delete method

  const [show33, setShow33] = useState(false);
  const [id33, setid33] = useState("");

  const [deleteid, setdeleteid] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (data) => {
    setShow33(true);
    setid33(data);
  };

  const Deletegraphic = async () => {
    try {
      const config = {
        url: "/admin/deleteGraphic/" + id33._id,
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: deleteid },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose33();
          GettingGraphic();
        }
      });
    } catch (error) {
      alert(error);
    }
  };
  console.log("getGraphics", getGraphics);
  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add Graphics
      </Button>
      <br />
      <br />
      <h4>Home Page</h4>
      <section className="container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Images</th>
              <th>Title</th>
              <th>Sub Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getGraphics.map((data, index) => {
              return (
                <tr>
                  <td>{++index}</td>
                  <td>
                    <img
                      className="photo1"
                      src={`https://brightbeginning.info/Slider/${data?.GraphicImg}`}
                    />
                  </td>

                  <td>{data.GraphicTitle}</td>
                  <td>{data.GraphicSubTitle}</td>
                  <td>{data.GraphicDis}</td>

                  <td>
                    <Button
                      className="mx-2"
                      variant="danger"
                      onClick={() => {
                        handleShow33(data);
                        setdeleteid(data._id);
                      }}
                    >
                      Delete
                    </Button>
                    {/* <Button 
                      variant="success"
                     
                      
                    >
                      Edit
                    </Button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      {/* <div style={{ display: "grid",gridTemplateColumns:"auto auto auto auto", padding: "2%" }}>
      {getGraphics.map((data, index) => {
            return ( 
          <div>
            <div style={{ padding: "0% 1%" }}>
        
                <img className="photo1" src={`https://brightbeginning.info/Slider/${data?.GraphicImg}`}/>
          
              <RiDeleteBin2Fill
                style={{
                  marginTop: "-15%",
                  marginLeft: "-0%",
                  color: "red",
                  fontSize: "20px",
                }}
                onClick={() => {handleShow33(data);setdeleteid(data._id)}}
               />
               <br/>
               <h6>{data.GraphicTitle}</h6>
               <h6>{data.GraphicSubTitle}</h6>
               <h6>{data.GraphicDis}</h6>
            </div>
            </div>
          )})}
          
        </div> */}

      <Modal show={show} onHide={handleClose} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Add New Banner</h5>
          </div>
          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Banner Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Banner Title..."
              onChange={(e) => setGraphicTitle(e.target.value)}
            />
          </div>
          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Banner SubTitle </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Banner SubTitle..."
              onChange={(e) => setGraphicSubTitle(e.target.value)}
            />
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Banner Discription </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Banner Discription..."
              onChange={(e) => setGraphicDis(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              padding: "7% 7%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%", marginBottom: "1%" }}>
              <label htmlFor="upload1" className="upload">
                <span>
                  <p className="graphicslabel">Upload </p>
                  <input
                    type="file"
                    accept="image*/"
                    onChange={(e) => setGraphicImg(e.target.files[0])}
                  />
                </span>
              </label>
            </div>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={handleClose}
              className="graphicsinput1"
              style={{ backgroundColor: "#575366" }}
            >
              Back
            </button>
            <button
              onClick={() => AddGraphic()}
              className="graphicsinput1"
              style={{ backgroundColor: "#0abcc9" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={show33} onHide={handleClose33} animation={false}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button variant="danger" onClick={() => Deletegraphic()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Addgraphis;
