import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import "../Testimonial/Testimonial.css";

const AddIntroduction = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // integrating post method for Intro

  const obj = new FormData();
  const [IntroTitle, setIntroTitle] = useState("");
  const [IntroDis, setIntroDis] = useState("");

  const AddIntro = async () => {
    obj.set("IntroTitle", IntroTitle);
    obj.set("IntroDis", IntroDis);
    try {
      if (!IntroTitle) {
        return alert("Please Add Title")
      }
      if (!IntroDis) {
        return alert("Please Add Description")
      }
      const config = {
        url: "/admin/AddIntro",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: { IntroTitle: IntroTitle, IntroDis: IntroDis }
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingIntro();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // integrating get method for Intro
  const [getIntro, setgetIntro] = useState([])
  const [intro, setIntro] = useState({})
  const GettingIntro = async () => {
    const res = await axios.get("https://brightbeginning.info/api/admin/listofIntro");
    if (res.status === 200) {
      setgetIntro(res.data.ListofIntro);

    }
  }
  useEffect(() => {
    GettingIntro()
  }, [])

  //  console.log("intro",intro);

  // integrating Delete method 
  // integrating Delete method

  const [show33, setShow33] = useState(false);
  const [id33, setid33] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setid33(item._id);
  };

  const DeleteIntro = async () => {
    try {

      const config = {
        url: "/admin/deleteIntro",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: id33 },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose33();
          GettingIntro();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  const [edit, setedit] = useState();
  const [intitle, setintitle] = useState();
  const [indiscription, setindiscription] = useState();
  const EditIntro = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/updateIntroduction",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          id: edit,
          IntroTitle: intitle,
          IntroDis: indiscription,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.Success);
        
          handleClose1();
          GettingIntro();
        } else {
          alert("error in edit");
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <Button style={{
        backgroundColor: "rgb(2 174 239)",
        padding: "10px",
        color: "white",
        border: "1px solid white",
        fontWeight: "600",
        marginLeft: "10%",
      }} onClick={handleShow}>
        Add Introduction
      </Button>


      <br />
      <br />

      <table striped bordered hover>
        <thead>
          <th>
            Sl.No
          </th>
          <th>
            Title
          </th>
          <th>
            Description
          </th>
          <th>
            Action
          </th>
        </thead>

        <tbody>
          {getIntro.map((data, index) => {
            return (
              <tr>
                <td>
                  {++index}
                </td>
                <td>
                  {data.IntroTitle}
                </td>
                <td>
                <p style={{height:"200px",overflow:"hidden",overflowY:"auto"}}>{data.IntroDis}</p> 
                </td>
                <td>
                  <Button
                    variant="danger"
                    className="block-btn mx-2"
                    onClick={() => {
                      handleShow33(data);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => {
                      handleShow1();
                      setedit(data._id)
                      setIntro(data)
                    }}

                  >
                    Edit
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>






      <Modal show={show} onHide={handleClose} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Add Introduction</h5>
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Title"
              onChange={(e) => setIntroTitle(e.target.value)}
            />
          </div>

          <div style={{ padding: "0% 2%" }}>
            <p className="graphicslabel">Description </p>
            <textarea
              type="text"
              className="graphicsinput"
              placeholder="IntroDis.."
              onChange={(e) => { setIntroDis(e.target.value) }}
            ></textarea>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={handleClose}
              className="graphicsinput1"
              style={{ backgroundColor: "#575366" }}
            >
              Back
            </button>
            <button
              onClick={AddIntro}
              className="graphicsinput1"
              style={{ backgroundColor: "rgb(2 174 239)" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={show33} onHide={handleClose33} animation={false}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button variant="danger" onClick={DeleteIntro}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Edit Introduction</h5>
          </div>
          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder={intro?.IntroTitle}
              onChange={(e) => setintitle(e.target.value)}
            />
          </div>

          <div style={{ padding: "0% 2%" }}>
            <p className="graphicslabel">Description </p>
            <textarea
              type="text"
              className="graphicsinput"
              placeholder={intro?.IntroDis}
              onChange={(e) => { setindiscription(e.target.value) }}
              style={{ height: "151px" }}
            ></textarea>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={handleClose1}
              className="graphicsinput1"
              style={{ backgroundColor: "#575366" }}
            >
              Back
            </button>
            <button
              onClick={EditIntro}
              className="graphicsinput1"
              style={{ backgroundColor: "rgb(2 174 239)" }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>

    </>
  )
}

export default AddIntroduction