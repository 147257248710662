import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import DarkModeToggle from "./DarkModeToggle";
import axios from "axios";
import { CgProfile } from "react-icons/cg";

function Header() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const logout = () => {
    sessionStorage.removeItem("user");
    window.location.assign("/");
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // integrating get method for Intro
  const [getBookCourse, setgetBookCourse] = useState([]);
  const GettingBookCourse = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofBookCourse"
    );
    if (res.status === 200) {
      setgetBookCourse(res.data.listofBookCourse);
    }
  };
  useEffect(() => {
    GettingBookCourse();
  }, []);

  // integrating Delete method

  const [show33, setShow33] = useState(false);
  const [id33, setid33] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setid33(item._id);
  };

  const deleteBookCourse = async () => {
    try {
      const config = {
        url: "/admin/deleteBookCourse",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: id33 },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose33();
          GettingBookCourse();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div>
        {user ? (
          <>
            <div className="header-nav">
              <Navbar expand="lg">
                <Container fluid>
                  <Navbar.Brand href="#home">
                    <img
                      src="/brightbeginning1.jpg"
                      alt="logo"
                      style={{ width: "173px", height: "135px" }}
                    />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                      <Nav.Link className="hover-underline-animation" href="/">
                        {" "}
                        Home
                      </Nav.Link>
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/about"
                      >
                        {" "}
                        About us
                      </Nav.Link>
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/courses"
                      >
                        {" "}
                        Courses
                      </Nav.Link>

                      <Nav.Link
                        className="hover-underline-animation"
                        href="/photos"
                      >
                        Gallery
                      </Nav.Link>
                      {/* <Nav.Link className="hover-underline-animation" href="/blogs">
                    Blog
                  </Nav.Link> */}
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/contact-us"
                      >
                        Contact
                      </Nav.Link>
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/login"
                      >
                        Login
                      </Nav.Link>
                      <div className="hover-underline-animation">
                        <DarkModeToggle />
                      </div>
                    </Nav>
                    {/* {getBookCourse?.map((data, index) => {
            return (  */}
                    <h6 className="fbt" onClick={handleShow } style={{  cursor: "pointer",}}>
                      <CgProfile
                        style={{
                          fontSize: "1rem",
                          color: "#e87d20 !important",
                          marginRight: "10px",
                      
                        }}
                      />
                      Profile
                    </h6>
                    {/* )})} */}
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </>
        ) : (
          <>
            <div className="header-nav">
              <Navbar expand="lg">
                <Container fluid>
                  <Navbar.Brand href="#home">
                    <img
                      src="/brightbeginning1.jpg"
                      alt="logo"
                      style={{ width: "173px", height: "135px" }}
                    />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                      <Nav.Link className="hover-underline-animation" href="/">
                        {" "}
                        Home
                      </Nav.Link>
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/about"
                      >
                        {" "}
                        About us
                      </Nav.Link>
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/courses"
                      >
                        {" "}
                        Courses
                      </Nav.Link>

                      <Nav.Link
                        className="hover-underline-animation"
                        href="/photos"
                      >
                        Gallery
                      </Nav.Link>
                      {/* <Nav.Link className="hover-underline-animation" href="/blogs">
                    Blog
                  </Nav.Link> */}
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/contact-us"
                      >
                        Contact
                      </Nav.Link>
                      <Nav.Link
                        className="hover-underline-animation"
                        href="/login"
                      >
                        Login
                      </Nav.Link>
                      <div className="hover-underline-animation">
                        <DarkModeToggle />
                      </div>
                    </Nav>
                    {/* {getBookCourse?.map((data, index) => {
            return (  */}
                    {/* <h6 className="" style={{color:"#e87d20"}}  onClick={handleShow}><CgProfile style={{fontSize:"1rem",color:"#e87d20 !important",marginRight:"10px"}}/>Profile</h6> */}
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h6>
              Name: <span>{user?.Name}</span>
            </h6>
            <h6>
              Phone: <span>{user?.Number}</span>
            </h6>
            <h6>
              Email: <span>{user?.Email}</span>
            </h6>
            <h6>
              <Button variant="danger" onClick={logout}>
                {" "}
                Logout{" "}
              </Button>
            </h6>
          </div>
          <br />
          Booked Courses
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Course Name</th>
                <th>Cancel</th>
              </tr>
            </thead>
            <tbody>
              {getBookCourse
                ?.filter((item) => item.BcName === user?.Name)
                .map((data, index) => {
                  return (
                    <tr>
                      <td>{++index}</td>
                      <td>{data.BcCourse}</td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => handleShow33(data)}
                        >
                          {" "}
                          Remove{" "}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal show={show33} onHide={handleClose33} animation={false}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteBookCourse}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;
