import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import Home from "./Components/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Components/Footer";
import About from "./Components/About";
import ITService from "./Components/ITService";
import Online from "./Components/Online";
import Header2 from "./Components/Header2";
import Login from "./Components/Login/Login";
import Blogs from "./Components/Blogs";
import Courses from "./Components/Courses";
import Course from "./Components/Course";
import Faculty from "./Components/Faculty";
import Adminview from "./Components/Admin/Adminview/Adminview";
import Adminlog from "./Components/Admin/Adminlog/Adminlog";
import AdminDashoard from './Components/Admin/AdminDashoard/AdminDashoard';
import Adminheader from './Components/Admin/Adminheader/Adminheader';
import GalleryAllPhotos from "./Components/GalleryAllPhotos";
import CoursesDetails from "./Components/CoursesDetails";
import Gallery from "./Components/Gallery";
import Contact from "./Components/Contact"

function App() {
 
  return (
    <>
      <div className="app">
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer/>
                </>
              }
            />
            <Route
              exact
              path="/about"
              element={
                <>
                  <Header />
                  <About />
                  <Footer/>
                </>
              }
            />
            <Route path="/photos" element={<><Header/><Gallery/><Footer/></>}/>
            <Route path="/contact-us" element={<><Header/><Contact/><Footer/></>}/>
             <Route
              exact
              path="/services"
              element={
                <>
                  <Header />
                  <ITService />
                  <Footer/>
                </>
              }
            />
              
              <Route
              exact
              path="/galleryallphotos"
              element={
                <>
                  <Header />
                  <GalleryAllPhotos />
                  <Footer/>
                </>
              }
            />
            
               <Route
              exact
              path="/blogs"
              element={
                <>
                  <Header />
                  <Blogs />
                  <Footer/>
                </>
              }
            />
               <Route
              exact
              path="/c"
              element={
                <>
                  <Header />
                  <Course />
                  <Footer/>
                </>
              }
            />
               <Route
              exact
              path="/fc"
              element={
                <>
                  <Header />
                  <Faculty />
                  <Footer/>
                </>
              }
            />
            <Route
              exact
              path="/course"
              element={
                <>
                  <Header />
                  <Online />
                  <Footer/>
                </>
              }
            />
             <Route
              exact
              path="/courses"
              element={
                <>
                  <Header />
                  <Courses />
                  <Footer/>
                </>
              }
            />
              <Route
              exact
              path="/courses/:id"
              element={
                <>
                  <Header />
                  <CoursesDetails />
                  <Footer/>
                </>
              }
            />
               <Route
              exact
              path="/login"
              element={
                <>
                  <Header />
                  <Login />
                  <Footer/>
                </>
              }
            />
               <Route path="/admindashboard" element={<Adminview />} />
          <Route path="/admin" element={<Adminlog />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
