import React, { useState } from "react";
// import ChannelPheader from '../ChannelPheader.js/ChannelPheader'
import {
  MdDashboard,
  MdFeedback,
  MdGeneratingTokens,
  MdGraphicEq,
} from "react-icons/md";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { GiHamburgerMenu, GiPriceTag } from "react-icons/gi";
import AdminDashoard from "../AdminDashoard/AdminDashoard";
import "./Adminview.css";
import Adminheader from "../Adminheader/Adminheader";
import AdminStudent from "../AdminStudent/AdminStudent";
import AddCollege from "../AddCollege/AddCollege";
import Testimonial from "../Testimonial/Testimonial";
import Addvideo from "../AddVideo/Addvideo";
import Querylist from "../Querylist/Querylist";
import Addgraphis from "../Addgraphic/Addgraphis";
import Addcourses from "../AddCourse/AddCourse";
import Advertisment from "../Advertisment/Advertisment";
import AddAnnouncement from "../AddAnnouncement/AddAnnouncement.jsx";
import AddIntroduction from "../AddIntroduction/AddIntroduction";
import AddCoursesOffered from "../AddCoursesOffered/AddCoursesOffered";
import AddPrincipal from "../AddPrincipal/AddPrincipal";
import AddFaculty from "../AddFaculty/AddFaculty";
import AddFeature from "../AddFeature/AddFeature";
import AddFooter from "../AddFooter/AddFooter";
import AddGallery from "../AddGallery/AddGallery";
import SubGallery from "../AddGallery/SubGallery";
import StudentReg from "../AdminStudent/StudentReg";
import Contact from "../Contact/Contact"

const Adminview = () => {

  // const admin = JSON.parse(sessionStorage.getItem("admin"));
  // if(!admin){
  //   window.location.assign("/admin");
  // }


  const [modalShow, setModalShow] = React.useState(false);

  const [visible, setVisible] = useState(false);
  const [tabActive, setTabActive] = useState(0);


  const [tabItems, setTabItems] = useState([
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Dashboard",
      isActive: tabActive === 0 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Graphics",
      isActive: tabActive === 1 ? true : false,
    },
  
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Introduction",
      isActive: tabActive === 2 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Courses Offered",
      isActive: tabActive === 3 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Courses",
      isActive: tabActive === 4 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Principal Details",
      isActive: tabActive === 5 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Faculty Details",
      isActive: tabActive === 6 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Features",
      isActive: tabActive === 7 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Gallery",
      isActive: tabActive === 8 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Sub Gallery",
      isActive: tabActive === 9 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "QueryList",
      isActive: tabActive === 10 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Booked List",
      isActive: tabActive === 11 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Student Registered",
      isActive: tabActive === 12 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Add Footer",
      isActive: tabActive === 13 ? true : false,
    },
    {
      icon: <MdDashboard className="accountsellerIcon" />,
      name: "Contact",
      isActive: tabActive === 14 ? true : false,
    },
    // {
    //   icon: <MdDashboard className="accountsellerIcon" />,
    //   name: "Add Colleges",
    //   isActive: tabActive ===  5? true : false,
    // },
   
    // {
    //   icon: <MdDashboard className="accountsellerIcon" />,
    //   name: "AddTestimonials",
    //   isActive: tabActive === 3 ? true : false,
    // },
    // {
    //   icon: <MdDashboard className="accountsellerIcon" />,
    //   name: "Addvideo",
    //   isActive: tabActive === 4 ? true : false,
    // },
    // {
    //   icon: <MdDashboard className="accountsellerIcon" />,
    //   name: "Add Advertisment",
    //   isActive: tabActive === 8 ? true : false,
    // },
    // {
    //   icon: <MdDashboard className="accountsellerIcon" />,
    //   name: "Add Announcement",
    //   isActive: tabActive === 9 ? true : false,
    // },
  ]);

  function TabContent(tabActive, makeSideTabActive) {
    if (tabActive == 0) {
      return <AdminDashoard makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 1) {
      return <Addgraphis makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 2) {
      return <AddIntroduction makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 3) {
      return <AddCoursesOffered makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 4) {
      return <Addcourses makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 5) {
      return <AddPrincipal makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 6) {
      return <AddFaculty makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 7) {
      return <AddFeature makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 8) {
      return <AddGallery makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 9) {
      return <SubGallery makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 10) {
      return <Querylist makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 11) {
      return <AdminStudent makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 12) {
      return <StudentReg makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 13) {
      return <AddFooter makeSideTabActive={makeSideTabActive} />;
    }
    if (tabActive == 14) {
      return <Contact makeSideTabActive={makeSideTabActive} />;
    }
    // if (tabActive == 2) {
    //   return <AddCollege makeSideTabActive={makeSideTabActive} />;
    // }
    // if (tabActive == 3) {
    //   return <Testimonial makeSideTabActive={makeSideTabActive} />;
    // }
    // if (tabActive == 4) {
    //   return <Addvideo makeSideTabActive={makeSideTabActive} />;
    // }
   
    // if (tabActive == 8) {
    //   return <Advertisment makeSideTabActive={makeSideTabActive} />;
    // }
    // if (tabActive == 9) {
    //   return <AddAnnouncement makeSideTabActive={makeSideTabActive} />;
    // }
  }

  const makeSideTabActive = (index) => {
    let tempArray = [...tabItems];
    tempArray.map((item, index) => {
      item.isActive = false;
    });
    tempArray[index].isActive = true;
    setTabActive(index);

    setTabItems(tempArray);
    setVisible(false);
  };
  return (
    <>
      <>
        <Adminheader />

        <Row>
          <div className="accountSellerMenuBarLayout">
            <div className="accountSellerMenuBar">
              <GiHamburgerMenu
                id="GiHamburgerMenu"
                onClick={() => setVisible(!visible)}
              />
            </div>
            {visible ? (
              <div className="accountsellersideTabMobile">
                {tabItems.map((item, index) => {
                  return (
                    <div
                      className={
                        item.isActive
                          ? "accountsellerTabItemSelected"
                          : "accountsellerTabItemUnSelected"
                      }
                      onClick={() => makeSideTabActive(index)}
                    >
                      <div className="accountsellerIconArea">{item.icon}</div>
                      <div
                        className={
                          item.isActive
                            ? "accountsellerTextSelected"
                            : "accountsellerTextUnSelected"
                        }
                      >
                        {item.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </Row>
        <Row className="h-100 layoutRightSideBg">
          <Col
            xs={12}
            sm={12}
            md={3}
            lg={2}
            // xl={3}
            className="accountsellersideTab "
          >
            {/* md="auto" */}
            <div className="accountsellersideTab">
              {tabItems.map((item, index) => {
                return (
                  <div
                    className={
                      item.isActive
                        ? "accountsellerTabItemSelected"
                        : "accountsellerTabItemUnSelected"
                    }
                    onClick={() => makeSideTabActive(index)}
                  >
                    <div className="accountsellerIconArea">{item.icon}</div>
                    <div
                      className={
                        item.isActive
                          ? "accountsellerTextSelected"
                          : "accountsellerTextUnSelected"
                      }
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={10}
            lg={10}
            xl={10}
            className="py-3"
            style={{ minHeight: "120vh", height: "100%", background: "white" }}
          >
            <div className="TabLayout">
              {TabContent(tabActive, makeSideTabActive)}
            </div>
          </Col>
        </Row>
        <Modal
          // size="xl"
          show={modalShow}
          onHide={() => setModalShow(false)}
          dialogClassName="modaltradeMarks"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-xs">
              Please fill the form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <BuyerMoreDetails setModalShow={setModalShow} /> */}
            {/* <div className="agreethanksDiv">
                        <button className="agreethanks" onClick={()=>setModalShow(false)}>Submit</button>
                    </div> */}
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default Adminview;
