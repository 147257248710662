import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";

function AddFeature() {
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // integrating post method for Feature

  const obj = new FormData();
  const [FeatureTitle, setFeatureTitle] = useState("");
  const [FeatureDis, setFeatureDis] = useState("");

  const AddFeature = async () => {
    obj.set("FeatureTitle", FeatureTitle);
    obj.set("FeatureDis", FeatureDis);
    try {
      if(!FeatureTitle){
        return alert("Please Add Title ")
      }
      if (!FeatureDis){
        return alert ("Please Add Description")
      }
      const config = {
        url: "/admin/AddFeature",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: { FeatureTitle: FeatureTitle, FeatureDis: FeatureDis },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingFeature();
          setFeatureTitle("");
          setFeatureDis("");
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // integrating get method for Feature
  const [getFeature, setgetFeature] = useState([]);
  const GettingFeature = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofFeature"
    );
    if (res.status === 200) {
      setgetFeature(res.data.ListofFeature);
    }
  };
  useEffect(() => {
    GettingFeature();
  }, []);

  // integrating Delete method
  // integrating Delete method

  const [show33, setShow33] = useState(false);
  const [id33, setid33] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setid33(item._id);
  };

  const DeleteFeature = async () => {
    try {
      const config = {
        url: "/admin/deleteFeature",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: id33 },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose33();
          GettingFeature();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  const [edit, setedit] = useState();
  const [fatureTitle, setfatureTitle] = useState();
  const [featureDis, setfeatureDis] = useState();
  const Editfeatured = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/UpdateFeature",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          id: edit,
          FeatureTitle: fatureTitle,
          FeatureDis: featureDis,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.Success);
          console.log("Success");
          handleClose1();
          GettingFeature();
        } else {
          alert("error in edit");
        }
      });
    } catch (error) {
      alert(error.res.data.error);
    }
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add Features
      </Button>
      <br />
      <br />

      <section className="container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SI No</th>
              {/* <th>Logo</th> */}
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getFeature.map((data, index) => {
              return (
                <tr>
                  <td style={{textAlign:"left"}}>{++index}</td>
                  <td style={{textAlign:"left"}}>{data.FeatureTitle}</td>
                  <td style={{textAlign:"left"}}><p style={{height:"200px",overflow:"hidden",overflowY:"auto"}}>{data.FeatureDis}</p></td>
                  <td style={{textAlign:"left"}}>
                    <Button
                      variant="danger"
                      className="block-btn mx-2"
                      onClick={() => {
                        handleShow33(data);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        handleShow1();
                        setedit(data._id);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>

      <Modal show={show} onHide={handleClose} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Add Feature</h5>
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Title"
              onChange={(e) => setFeatureTitle(e.target.value)}
            />
          </div>

          <div style={{ padding: "0% 2%" }}>
            <p className="graphicslabel">Description </p>
            <textarea
              type="text"
              className="graphicsinput"
              placeholder="FeatureDis.."
              onChange={(e) => {
                setFeatureDis(e.target.value);
              }}
            ></textarea>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={handleClose}
              className="graphicsinput1"
              style={{ backgroundColor: "#575366" }}
            >
              Back
            </button>
            <button
              onClick={AddFeature}
              className="graphicsinput1"
              style={{ backgroundColor: "rgb(2 174 239)" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={show33} onHide={handleClose33} animation={false}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button variant="danger" onClick={DeleteFeature}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Update Feature</h5>
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Title"
              value={fatureTitle}
              onChange={(e) => setfatureTitle(e.target.value)}
            />
          </div>

          <div style={{ padding: "0% 2%" }}>
            <p className="graphicslabel">Description </p>
            <textarea
              type="text"
              className="graphicsinput"
              placeholder="FeatureDis.."
              value={featureDis}
              onChange={(e) => {
                setfeatureDis(e.target.value);
              }}
            ></textarea>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={handleClose1}
              className="graphicsinput1"
              style={{ backgroundColor: "#575366" }}
            >
              Back
            </button>
            <button
              onClick={Editfeatured}
              className="graphicsinput1"
              style={{ backgroundColor: "rgb(2 174 239)" }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddFeature;
