import React, { useState } from "react";
import "./Adminlog.css";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import axios from "axios";
import Logo from "../Pics/Logo.jpeg";

const Login1 = () => {
  const [hremail, sethremail] = useState("");
  const [hrpassword, sethrpassword] = useState("");

  const submit1 = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/Adminlog",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "application/json" },
        data: { Adminmail: hremail, password: hrpassword },
      };
      await axios(config).then(function (res) {
        console.log(res.status);
        if (res.status === 200) {
          console.log(res);
          alert("login sucessfull");
          sessionStorage.setItem("admin", JSON.stringify(res.data.details));
          window.location.assign("/admindashboard");
        } else {
          alert("Invalid Email or Password");
        }
      });
    } catch (error) {
      console.log(error);
      alert("invalid Email or Password!!!!!!");
    }
  };

  return (
    <div className="page-single">
      <div className="container">
        <div className="row">
          <div className="col mx-auto">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-4 col-xxl-4">
                <div className="card my-5 home_page">
                  <div className="p-2 pt-3 text-center">
                    <img
                      src="/brightbeginning1.jpg"
                      alt="logo"
                      style={{ width: "100%", height: "160px" }}
                    />
                  </div>
                  {/* <Tabs
                    defaultActiveKey="profile"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                  > */}
                  {/* <Tab eventKey="home" title="Hr"> */}
                  <div className="p-2 pt-3 text-center">
                    {/* <h1
                          className="mb-2"
                          style={{
                            fontSize: "34px",
                            marginTop: "10px",
                            color: "black",
                          }}
                        >
                          Admin
                        </h1> */}
                    <h1
                      className="text-muted"
                      style={{ fontSize: "30px", marginTop: "5px" }}
                    >
                      Admin Login
                    </h1>
                  </div>
                  <form className="card-body pt-2" id="login" name="login">
                    <div className="form-group">
                      <label className="form-label mb-2">ID</label>
                      <div className="input-group mb-4">
                        <div className="input-group">
                          <a href="" className="input-group-text">
                            <i className="fe fe-mail" aria-hidden="true">
                              <AiOutlineMail />
                            </i>
                          </a>
                          <input
                            className="form-control"
                            placeholder="faith@gmail.com"
                            value={hremail}
                            onChange={(e) => sethremail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label mb-2">Password</label>
                      <div className="input-group mb-2">
                        <div className="input-group" id="Password-toggle">
                          <a href="" className="input-group-text">
                            <i className="fe fe-eye-off" aria-hidden="true">
                              <AiOutlineEyeInvisible />
                            </i>
                          </a>
                          <input
                            className="form-control"
                            type="password"
                            placeholder="......."
                            value={hrpassword}
                            onChange={(e) => sethrpassword(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="example-checkbox1"
                              value="option1"
                            />
                            <span
                              className="custom-control-label"
                              style={{
                                margin: "5px",
                                fontSize: "15px",
                                color: "black",
                              }}
                            >
                              Remember me
                            </span>
                          </label>
                        </div> */}
                    <div className="submit">
                      {/* <a className="loginbtn btn btn-primary btn-block" href="#">Login</a> */}

                      <button
                        className="loginbtn btn btn-primary btn-block"
                        onClick={(e) => submit1(e)}
                        style={{
                          backgroundColor: "#373187",
                          borderColor: "#373187",
                        }}
                      >
                        Login
                      </button>
                    </div>
                    <div className="text-center mt-3">
                      <p className="">
                        <a
                          // href="javascript:void(0);"
                          style={{
                            textDecoration: "none",
                            color: "red !important",
                          }}
                        >
                          {/* Forgot Password */}
                        </a>
                      </p>
                      {/* <p className="text-dark mb-0">Don't have account?<a className="text-primary ms-1" href="javascript:void(0);">Register</a></p> */}
                    </div>
                  </form>
                  {/* </Tab> */}
                  {/* </Tabs> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login1;
