import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiFacebook, FiInstagram, FiPhone, FiTwitter } from "react-icons/fi";
import { GrLinkedinOption } from "react-icons/gr";
import "../Styles/footer.css";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Footer() {
  // integrating get method for Intro
  const [getFooter, setgetFooter] = useState([]);
  const GettingFooter = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofFooter"
    );
    if (res.status === 200) {
      setgetFooter(res.data.listofFooter);
    }
  };
  useEffect(() => {
    GettingFooter();
  }, []);
  return (
    <>
      <div className="atf-footer-area">
        <Container>
          <Row className="atf-section-padding">
            <Col md={4} lg={4}>
              <div className="atf-footer-link">
                <div className="atf-footer-box">
                  <h4
                    style={{
                      fontSize: "21px",
                      fontWeight: "700",
                      color: "rgb(251 193 24)",
                    }}
                  >
                    Address
                  </h4>
                  {getFooter.map((data, index) => {
                    return (
                      <div className="pe-lg-3" style={{ color: "white" }}>
                        {data.FAddress},
                      </div>
                    );
                  })}
                  
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="atf-footer-link">
                <h4
                  style={{
                    fontSize: "21px",
                    fontWeight: "700",
                    color: "rgb(251 193 24)",
                  }}
                >
                  Quick Links
                </h4>
                <ul className="atf-list-menu">
                  <li>
                    {" "}
                    <a href="/">&#x27A4; Home</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/about">&#x27A4; About Us</a>
                  </li>
                  {/* <li>
                    {" "}
                    <a href="#">&#x27A4; Carrier</a>
                  </li> */}
                  <li>
                    {" "}
                    <a href="/courses">&#x27A4; Courses</a>
                  </li>
                  {/* <li>
                    {" "}
                    <a href="#">&#x27A4; Teacher</a>
                  </li> */}
                  <li>
                    {" "}
                    <a href="/contact-us">&#x27A4; Contact Us</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="atf-footer-link">
                <div className="atf-footer-box">
                  <h4
                    className="atf-footer-link"
                    style={{
                      fontSize: "21px",
                      fontWeight: "700",
                      color: "rgb(251 193 24)",
                    }}
                  >
                    Contact Numbers
                  </h4>
                  <ul className="atf-list-menu">
                    {getFooter.slice(0, 1).map((data, index) => {
                      return (
                        <li>
                          {" "}
                          <span className="px-2">
                            {" "}
                            <FiPhone
                              style={{ color: "white", fontSize: "20px" }}
                            />
                          </span>
                          <a href="#">{data.FContact}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="atf-footer-social-icon mt-4 d-flex">
                <div className="icon">
                  {" "}
                  <FiFacebook className="icon-face" />{" "}
                </div>
                <div className="icon">
                  <FiInstagram className="icon-face" />{" "}
                </div>
                <div className="icon">
                  <GrLinkedinOption className="icon-face" />{" "}
                </div>
                <div className="icon">
                  <FiTwitter className="icon-face" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
