import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import About from "./About";
import Counter from "./Counter";
import Course from "./Course";
import Feature from "./Feature";
import ITService from "./ITService";
import Online from "./Online";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
// import Animista, { AnimistaTypes } from "react-animista";
function Home() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  // integrating get method for graphic

  //  get method for Principal
  const [getGraphic, setgetGraphic] = useState([]);

  const GettingGraphic = async () => {
    let res = await axios.get(
      "https://brightbeginning.info/api/admin/getGraphic"
    );
    if (res.status === 200) {
      setgetGraphic(res.data.getGraphic);
    }
  };

  useEffect(() => {
    GettingGraphic();
  }, []);

  console.log(getGraphic);

  // integrating get method for Intro
  const [getIntro, setgetIntro] = useState([]);
  const GettingIntro = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofIntro"
    );
    if (res.status === 200) {
      setgetIntro(res.data.ListofIntro);
    }
  };
  useEffect(() => {
    GettingIntro();
  }, []);

  return (
    <>
      <section>
        <div className="h_m">
          <div>
            <Slider {...settings}>
              {/* 1st */}
              {getGraphic?.map((item, index) => {
                return (
                  <div>
                    <div className="banner_hero">
                      <div className="banner jdk">
                        <div
                          className="bg-banner kenburns-top"
                          style={{
                            backgroundImage: `url('https://brightbeginning.info/Slider/${item?.GraphicImg}')`,
                            height: "551px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                      </div>
                      <div className="text-b">
                        <Container>
                          <Row>
                            <Col lg={7} md={7}>
                              <div className="collage_dif">
                                <div className="atf-hero-text">
                                  <h5 className="atf_sub_title_base">
                                    {item.GraphicTitle}
                                  </h5>
                                  <h1 className="atf-hero-title atf-title-base">
                                    {item.GraphicSubTitle}
                                  </h1>
                                  <div
                                    className="atf-hero-subtitle mb-3 nbb"
                                    style={{ color: "white" }}
                                  >
                                    {item.GraphicDis}
                                  </div>
                                  <div className="atf-hero-btn">
                                    <Link
                                      to="/about"
                                      className="atf-main-btn me-2"
                                    >
                                      Start Learn More
                                    </Link>
                                    <Link
                                      to="/contact-us"
                                      className="atf-main-btn"
                                    >
                                      Contact Now
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={5} md={5}></Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      {/*  */}
      <div className=" mt-5">
        {getIntro.map((data, index) => {
          return (
            <Container>
              <div className="feat_h">
                <div className="atf-section-title">
                  <h2 className="atf-title-color border-left ps-3">
                    {data.IntroTitle}
                    <span></span>
                  </h2>
                </div>
              </div>
              <div class="atf-about-title mt-md-40 atf-section-title">
                <p>{data.IntroDis}</p>
              </div>
            </Container>
          );
        })}
      </div>
      <Course />
      {/* <Feature/> */}
      <About />
      {/* <Counter/> */}
      {/* <Online/> */}
    </>
  );
}

export default Home;
