import React from "react";
import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useEffect } from "react";

function AddFooter() {
  const [show, setShow] = useState(false);
  // const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => setShow1(true);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // integrating post method for Footer

  const obj = new FormData();
  const [FAddress, setFAddress] = useState("");
  const [FContact, setFContact] = useState("");
  const [FFacebook, setFFacebook] = useState("");
  const [FInstagram, setFInstagram] = useState("");
  const [FLinkedin, setFLinkedin] = useState("");
  const [FTwitter, setFTwitter] = useState("");

  const AddFooter = async (e) => {
    e.preventDefault();
    obj.set("FAddress", FAddress);
    obj.set("FContact", FContact);
    obj.set("FFacebook", FFacebook);
    obj.set("FInstagram", FInstagram);
    obj.set("FLinkedin", FLinkedin);
    obj.set("FTwitter", FTwitter);

    try {
      const config = {
        url: "/admin/AddFooter",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          FAddress: FAddress,
          FContact: FContact,
          FFacebook: FFacebook,
          FInstagram: FInstagram,
          FLinkedin: FLinkedin,
          FTwitter: FTwitter,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingFooter();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };
  // integrating get method for Intro
  const [getFooter, setgetFooter] = useState([]);
  const GettingFooter = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofFooter"
    );
    if (res.status === 200) {
      setgetFooter(res.data.listofFooter);
    }
  };
  useEffect(() => {
    GettingFooter();
  }, []);

  // delete method for Principal

  const [showdelete, setShowdelete] = useState(false);
  const [iddelete, setiddelete] = useState("");

  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (data) => {
    setShowdelete(true);
    setiddelete(data._id);
  };

  const deleteFooter = async () => {
    try {
      const config = {
        url: "/admin/deleteFooter",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: iddelete },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClosedelete();
          GettingFooter();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

const[edit,setedit]=useState();
const[faderss,setfaddress]=useState();
const[fcontact,setfcontact]=useState();
  const EditFooter = async (e) => {
    e.preventDefault();
    
    try {
      const config = {
        url: "/admin/UpdateFooter",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          id:edit,
          FAddress: faderss,
          FContact: fcontact,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.Success);
          console.log("Success")
          handleClose1();
          GettingFooter();
        } else {
          alert("error in edit");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add
      </Button>
      <br />
      <br />

      <section className="container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Address</th>
              <th>Contact Number</th>
              <th>Action</th>
             
            </tr>
          </thead>
          <tbody>
            {getFooter.map((data, index) => {
              return (
                <tr>
                  <td>{++index}</td>
                  <td style={{ textAlign: "left" }}>{data.FAddress}</td>
                  <td style={{ textAlign: "left" }}>
                    <h6>{data.FContact}</h6>
                  </td>
                
                  
                  <td>
                    <Button
                    className="mx-2"
                      variant="danger"
                      onClick={() => handleShowdelete(data)}
                    >
                      Delete
                    </Button>
                    <Button 
                      variant="success"
                      onClick= {()=>{
                        handleShow1()
                        setedit(data._id)
                      }}
                      
                    >
                      Edit
                    </Button>
                   
                  </td>
                  
                
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>

      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Add Footer</h3>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: "100px" }}
              onChange={(e) => setFAddress(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setFContact(e.target.value)}
            />
          </Form.Group>
          <br />
     
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={AddFooter}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <Modal show={showdelete} onHide={handleClosedelete}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteFooter();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

       {/* edit */}
       <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Edit Footer</h3>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: "100px" }}
              value={faderss}
              onChange={(e)=> setfaddress(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              value={fcontact}
              onChange={(e)=> setfcontact(e.target.value)}
            
            />
          </Form.Group>
          <br />
     
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="danger" onClick={EditFooter}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddFooter;
