import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import "./AdminStudent.css";

const AdminStudent = () => {
  // integrating get method for Intro
  const [getBookCourse,setgetBookCourse] = useState([])
  const GettingBookCourse= async()=>{
    const res = await axios.get("https://brightbeginning.info/api/admin/listofBookCourse");
    if(res.status === 200){
      setgetBookCourse(res.data.listofBookCourse)
    }
  }
  useEffect(()=>{
    GettingBookCourse()
  },[])

  return (
    <>
      <button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
      >
        Course Booked List
      </button>
      <br />
      <br />

      <table id="table" striped bordered hover size="xl">
        <thead>
          <th id="th">Slno</th>
          <th>Name</th>
          <th>Number</th>
          <th>Email</th>
          <th> Course</th>
        </thead>
        <tbody>
        {getBookCourse?.map((data, index) => {
            return ( 
              <tr>
              <td style={{textAlign:"left"}}>
                {++index}
              </td>
              <td style={{textAlign:"left"}}>
              {data.BcName}
              </td>
              <td style={{textAlign:"left"}}>
                {data.BcPhone}
              </td>
              <td style={{textAlign:"left"}}>
                {data.BcEmail}
              </td>
              <td style={{textAlign:"left"}}>
                {data.BcCourse}
              </td>
              </tr>
          )})}
        </tbody>
      </table>
    </>
  );
};

export default AdminStudent;
