import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../Testimonial/Testimonial.css";

const AddCoursesOffered = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // integrating post method for Co

  const obj = new FormData();
  const [CoTitle, setCoTitle] = useState("");
  const [CoSubTitle, setCoSubTitle] = useState("");
  const [CoDis, setCoDis] = useState("");

  const AddCo = async () => {
    obj.set("CoTitle", CoTitle);
    obj.set("CoSubTitle", CoSubTitle);
    obj.set("CoDis", CoDis);
    try {
      const config = {
        url: "/admin/AddCo",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: { CoTitle: CoTitle, CoSubTitle: CoSubTitle, CoDis: CoDis },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingCo();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // integrating get method for Co
  const [getCo, setgetCo] = useState([]);
  const GettingCo = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/listofCo"
    );
    if (res.status === 200) {
      setgetCo(res.data.ListofCo);
    }
  };
  useEffect(() => {
    GettingCo();
  }, []);

  // integrating Delete method
  // integrating Delete method

  const [show33, setShow33] = useState(false);
  const [id33, setid33] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setid33(item._id);
  };

  const DeleteCo = async () => {
    try {
      const config = {
        url: "/admin/deleteCo",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: id33 },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose33();
          GettingCo();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  const [edit, setedit] = useState();
  const [coTitle, setcoTitle] = useState();
  const [coSubTitle, setcoSubTitle] = useState();
  const [coDis, setcoDis] = useState();
  const EditCourseoffered = async () => {
  
    try {
      const config = {
        url: "/admin/Updatecourse12",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        headers:  { "content-type": "application/json" },
        data: {
          id: edit,
          CoTitle: coTitle,
          CoSubTitle: coSubTitle,
          CoDis: coDis,
        },
      };
     
     let res=await axios(config)
     console.log(res);
        if(res.status == 200) {
          alert("Successfully updated");
          handleClose1();
          GettingCo();
        }
        
    } catch (error) {
      console.log(error,"reorere")
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add Courses Offered
      </Button>

      <br />
      <br />
      <table striped bordered hover>
        <thead>
          <th>Sl No</th>
          <th>Title</th>
          <th>Subtitle</th>
          <th>Description</th>
          <th>Action</th>
        </thead>
        <tbody>
          {getCo.map((data, index) => {
            return (
              <tr>
                <td style={{textAlign:"left"}}>{++index}</td>
                <td style={{textAlign:"left"}}>{data.CoTitle}</td>
                <td style={{textAlign:"left"}}>{data.CoSubTitle}</td>
                <td style={{textAlign:"left"}}>
                  <p
                    style={{
                      height: "180px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    {data.CoDis}
                  </p>
                </td>
                <td>
                  <Button
                    variant="danger"
                    className="block-btn mx-2"
                    onClick={() => {
                      handleShow33(data);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => {
                      setedit(data?._id);
                      handleShow1();
                     
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal show={show} onHide={handleClose} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Add Courses Offered</h5>
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Title"
              onChange={(e) => {
                setCoTitle(e.target.value);
              }}
            />
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Subtitle </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Subtitle"
              onChange={(e) => {
                setCoSubTitle(e.target.value);
              }}
            />
          </div>

          <div style={{ padding: "0% 2%" }}>
            <p className="graphicslabel">Description </p>
            <textarea
              type="text"
              className="graphicsinput"
              placeholder="Description.."
              // value={redirecturl}
              onChange={(e) => {
                setCoDis(e.target.value);
              }}
            ></textarea>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={handleClose}
              className="graphicsinput1"
              style={{ backgroundColor: "#575366" }}
            >
              Back
            </button>
            <button
              onClick={AddCo}
              className="graphicsinput1"
              style={{ backgroundColor: "rgb(2 174 239)" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={show33} onHide={handleClose33} animation={false}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Close
          </Button>
          <Button variant="danger" onClick={DeleteCo}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit */}
      <Modal show={show1} onHide={handleClose1} size="lg">
        <div className="graphicscontainer">
          <div className="alignright">
            <h5>Update Courses Offered</h5>
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Title </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Title"
              value={coTitle}
              onChange={(e) => {
                setcoTitle(e.target.value);
              }}
            />
          </div>

          <div style={{ padding: "1% 2%" }}>
            <p className="graphicslabel">Subtitle </p>
            <input
              type="text"
              className="graphicsinput"
              placeholder="Subtitle"
              value={coSubTitle}
              onChange={(e) => {
                setcoSubTitle(e.target.value);
              }}
            />
          </div>

          <div style={{ padding: "0% 2%" }}>
            <p className="graphicslabel">Description </p>
            <textarea
              type="text"
              className="graphicsinput"
              placeholder="Description.."
              value={coDis}
              onChange={(e) => {
                setcoDis(e.target.value);
              }}
            ></textarea>
          </div>

          <div style={{ textAlign: "center", backgroundColor: "#c8f5f5" }}>
            <button
              onClick={EditCourseoffered}
              className="graphicsinput1"
              style={{ backgroundColor: "rgb(2 174 239)" }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddCoursesOffered;
