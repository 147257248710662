import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";

function Addcourses() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // integrating post method for College
  const formdata = new FormData();
  const [Branch, setBranch] = useState("");
  const [Stream, setStream] = useState("");
  const [Fee, setFee] = useState("");
  const [Course, setCourse] = useState("");
  const [Duration, setDuration] = useState("");
  const [EntranceExam, setEntranceExam] = useState("");
   const [CoursePhoto, setCoursePhoto] = useState("");
  const [CourseDis, setCourseDis] = useState("");
  const [TotalSubject, setTotalSubject] = useState("");
  const [ProfessorImage, setProfessorImage] = useState("");
  const [ProfessorName, setProfessorName] = useState("");
  const [ProfessorDesignation, setProfessorDesignation] = useState("");




  const AddallCourse = async () => {
    formdata.set("Branch", Branch);
    formdata.set("Stream", Stream);
    formdata.set("Course", Course);
    formdata.set("Duration", Duration);
    formdata.set("EntranceExam", EntranceExam);
    formdata.set("CoursePhoto", CoursePhoto);
    formdata.set("CourseDis", CourseDis);
    formdata.set("Fee", Fee);
    formdata.set("TotalSubject", TotalSubject);
    formdata.set("ProfessorImage", ProfessorImage);
    formdata.set("ProfessorName", ProfessorName);
    formdata.set("ProfessorDesignation", ProfessorDesignation);
    console.log(Branch)
console.log("CHECK IT",formdata);

    try {
      const config = {
        url: "/admin/AddCourse",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        // header: { "content-type": "multipart/form-data" },
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingColleges();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // integrating get method for College

  const [getcollege, setgetcollege] = useState([]);

  const GettingColleges = async () => {
    let res = await axios.get("https://brightbeginning.info/api/admin/listofCourse");
    if (res.status === 200) {
      setgetcollege(res.data.ListofCourse);
    }
  };

  useEffect(() => {
    GettingColleges();
  }, []);

  // integrating Delete method

  const [show33, setShow33] = useState(false);
  const [id33, setid33] = useState("");

  const handleClose33 = () => setShow33(false);
  const handleShow33 = (item) => {
    setShow33(true);
    setid33(item._id);
  };

  const DeleteCollege = async () => {
    try {
      const config = {
        url: "/admin/deleteCourse",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: id33 },
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose33();
          GettingColleges();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  // indetration for Edit

  const [Branch1, setBranch1] = useState("");
  const [Stream1, setStream1] = useState("");
  const [Duration1, setDuration1] = useState("");
  const [Category1, setCategory1] = useState("");
  const [Course1, setCourse1] = useState("");
  const [Coursetype1, setCoursetype1] = useState("");
  const [EntranceExam1, setEntranceExam1] = useState("");
  const [TotalSubject1, setTotalSubject1] = useState("");
  const [Fee1, setFee1] = useState("");
  const [CourseDis1, setCourseDis1] = useState("");
  const [CoursePhoto1, setCoursePhoto1] = useState("");
  const [ProfessorName1, setProfessorName1] = useState("");
  const [ProfessorImage1, setProfessorImage1] = useState("");
  const [ProfessorDesignation1, setProfessorDesignation1] = useState("");




  const data = new FormData();
  const [id, setid] = useState("");
  const [Category2, setCategory2] = useState("");
  const [Course2, setCourse2] = useState("");
  const [Coursetype2, setCoursetype2] = useState("");
  const [Branch2, setBranch2] = useState("");
  const [Stream2, setStream2] = useState("");
  const [Duration2, setDuration2] = useState("");
  const [EntranceExam2, setEntranceExam2] = useState("");
  const [TotalSubject2, setTotalSubject2] = useState("");
  const [CourseDis2, setCourseDis2] = useState("");
  const [Fee2, setFee2] = useState("");
  const [info, setinfo] = useState("");
  const [CoursePhoto2, setCoursePhoto2] = useState("");
  const [ProfessorName2, setProfessorName2] = useState("");
  const [ProfessorImage2, setProfessorImage2] = useState("");
  const [ProfessorDesignation2, setProfessorDesignation2] = useState("");


  
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setid(data._id);
    setinfo(data)
    setDuration1(data.Duration);
    setCourse1(data?.Course);
    setBranch1(data?.Branch);
    setStream1(data?.Stream);
    setEntranceExam1(data.EntranceExam);
    setTotalSubject1(data.TotalSubject);
    setFee1(data.Fee2);
    setCourseDis1(data.CourseDis);
    setCoursePhoto1(data.CoursePhoto);
    setProfessorName1(data.ProfessorName);
    setProfessorImage1(data.ProfessorImage);
    setProfessorDesignation1(data.ProfessorDesignation);
    
  };

  const editcourse = async () => {
   
    data.set("CourseId", id);
    data.set("Category", Category2);
    data.set("Coursetype", Coursetype2);
    data.set("Course", Course2);
    data.set("Branch", Branch2);
    data.set("Stream", Stream2);
    data.set("Duration", Duration2);
    data.set("EntranceExam", EntranceExam2);
    data.set("TotalSubject", TotalSubject2);
    data.set("Fee", Fee2);
    data.set("CourseDis", CourseDis2);
    data.set("CoursePhoto", CoursePhoto2);
    data.set("ProfessorName", ProfessorName2);
    data.set("ProfessorDesignation", ProfessorDesignation2);
    data.set("ProfessorImage", ProfessorImage2);

    try {
      const config = {
        url: "/admin/UpdateCourse",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: data,
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClose1();
          GettingColleges();
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };


  const AddMore = async () => {
  
    try {
      const config = {
        url: "/admin/Addcoursebranch",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: {CourseId:id,
          Branch:Branch1,
          Stream:Stream1
        },
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          GettingColleges()
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

 



  const removebranch = async (data) => {
    try {
      const config = {
        url: `admin/removeBranch`,
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data:{removeId:data._id,CourseId:id}
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        GettingColleges();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  // console.log(data._id ,",,,,",id,",,");

  return (
    <>
      <Button
        variant="primary"
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add Courses
      </Button>

      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <br/>
          <br />
          <Form.Label htmlFor="upload1" style={{fontSize:"600",marginTop:"-30px"}}>Course Photo :</Form.Label>
          <Form.Control
            type="file"
            // name="file"
            // id="upload1"
            accept="image/*"
            onChange={(e)=>{setCoursePhoto(e.target.files[0])}}
          />
          <br />
          <br/>
          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Course Name :</Form.Label>
          <Form.Control
            type="text"
            placeholder="Course Name"
            onChange={(e) => {
              setCourse(e.target.value);
            }}
          />
            <br/>
            <br />
            <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Branch :</Form.Label>
            <Form.Control
              type="text"
              value={Branch}
              placeholder="Branch"
              onChange={(e) => {
                setBranch(e.target.value);
              }}
            />
            <br />
            <br/>
            <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Stream :</Form.Label>
            <Form.Control
              type="text"
              value={Stream}
              placeholder="Streem"
              onChange={(e) => {
                setStream(e.target.value);
              }}
            />
            <br />
            <br/>

            {/* <Button
              variant="primary"
              onClick={() => {
                AddSpecific();
              }}
            >
              Add
            </Button> */}

          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}> Duration</Form.Label>
          <Form.Control
            type="text"
            placeholder="Duration"
            onChange={(e) => {
              setDuration(e.target.value);
            }}
          />
          <br />
          <br/>
          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Entrance Exam</Form.Label>
          <Form.Control
            type="text"
            placeholder="Entrance Exam"
            onChange={(e) => {
              setEntranceExam(e.target.value);
            }}
          />
          <br />
          <br/>
          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Total Subjects</Form.Label>
          <Form.Control
            type="text"
            placeholder="Total Subjects"
            onChange={(e) => {
              setTotalSubject(e.target.value);
            }}
          />
          <br/>
          <br />
          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Fee</Form.Label>
          <Form.Control
            type="text"
            placeholder="Total Subjects"
            onChange={(e) => {
              setFee(e.target.value);
            }}
          />
           <br/>
          <br />
          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Discription*"
            style={{ height: "100px" }}
            onChange={(e) => {
              setCourseDis(e.target.value);
            }}
          />
          <br />
          <br />
          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Professor Name</Form.Label>
          <Form.Control
            type="text"
            onChange={(e)=>setProfessorName(e.target.value)}
          />
            <br/>
          <br/>
          <Form.Label  style={{fontSize:"600",marginTop:"-30px"}}>Professor Designation</Form.Label>
          <Form.Control
            type="text"
            onChange={(e)=>setProfessorDesignation(e.target.value)}

          />
          <br/>
          <br/>
          <Form.Label htmlFor="upload2"  style={{fontSize:"600",marginTop:"-30px"}}>Professor Image</Form.Label>
          <Form.Control
            type="file"
            name="file"
            accept="image/*"
            id="upload2"
            onChange={(e)=>setProfessorImage(e.target.files[0])}
          />
          <br/>
          <br/>
          <Button variant="primary" onClick={AddallCourse}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
      <br />
      {/* edit modal */}
      {/* =================================== */}
      <br />
      <table className="tab">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Course Photo</th>
            <th>Course Name</th>
            <th>Branch</th>
            <th>Stream</th>
            <th>Duration</th>
            <th>Entrance Exam</th>
            <th>Total Subjects</th>
            <th>Fees</th>
            <th>Description</th>
            <th>Professor Name</th>
            <th>Professor Designation</th>
            <th>Professor Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {getcollege.map((data, index) => {
            return (
              <tr>
                <td>{++index}</td>
          
                <td><img className="c-image" src={`https://brightbeginning.info/Course/${data?.CoursePhoto}`} /></td>
                <td>{data.Course}</td>
                <td>{data.Branch}</td>
                <td>{data.Stream}</td>
                <td>{data.Duration}</td>
                <td>{data.EntranceExam}</td>
                <td>{data.TotalSubject}</td>
                <td>{data.Fee}</td>
                <td>{data.CourseDis.slice(0, 25)} ...</td>
                <td>{data.ProfessorName}</td>
                <td>{data.ProfessorDesignation}</td>
                <td><img className="c-image" src={`https://brightbeginning.info/Course/${data?.ProfessorImage}`} /></td>
                <td>
                  <Button className="mb-2" variant="success" onClick={() => {handleShow1(data)}}>
                    Edit
                  </Button>
                  <br />
                  <Button variant="success" onClick={() => handleShow33(data)}>
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>


      
      {/* modal */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <br />
          <Form.Label>Course Photo</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e)=>{setCoursePhoto2(e.target.files[0])}}
          />
          <br />
          <Form.Label>Course Name</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.Course}
            onChange={(e) => setCourse2(e.target.value)}
          />
          <br />
            <br />
            <Form.Label>Branch :</Form.Label>
            <Form.Control
              type="text"
              placeholder={info.Branch}
              onChange={(e) => {
                setBranch2(e.target.value);
              }}
            />
            <br />
            <Form.Label>Stream :</Form.Label>
            <Form.Control
              type="text"
              placeholder={info.Stream}
              onChange={(e) => {
                setStream2(e.target.value);
              }}
            />
       
          <br />
          <br />
          <Form.Label> Duration</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.Duration}
            onChange={(e) => setDuration2(e.target.value)}
          />
          <br />
          <Form.Label>Entrance Exam</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.EntranceExam}
            onChange={(e) => setEntranceExam2(e.target.value)}
          />
          <br />
          <Form.Label>Fee</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.Fee}
            onChange={(e) => setFee2(e.target.value)}
          />
          <br />
          <Form.Label>Total Subjects</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.TotalSubject}
            onChange={(e) => setTotalSubject2(e.target.value)}
          />
          <br />
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder={info.CourseDis}
            style={{ height: "100px" }}
            onChange={(e) => setCourseDis2(e.target.value)}
          />
          <br />
          <br/>
          <Form.Label>Professor Name</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.ProfessorName}
            onChange={(e) => setProfessorName2(e.target.value)}
          />
          <br/>
          <Form.Label>Professor Designation</Form.Label>
          <Form.Control
            type="text"
            placeholder={info.ProfessorDesignation}
            onChange={(e) => setProfessorDesignation2(e.target.value)}
          />
          <br/>
          <Form.Label>Professor Image</Form.Label>
          <Form.Control
            type="file"
            accept="image*/"
            onChange={(e)=> {setProfessorImage2(e.target.files[0])}}
          />
          <br/>
          <Button variant="primary" onClick={() => editcourse()}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>

      {/* delete modal */}
      <Modal show={show33} onHide={handleClose33}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Course</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose33}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              DeleteCollege();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Addcourses;
