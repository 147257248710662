import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import "./Courses.css";
import { Rate } from "antd";
import {
  MdFavorite,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdSupervisorAccount,
} from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdPageview } from "react-icons/md";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";

function Courses() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // integrating get method for College

  const [getcollege, setgetcollege] = useState([]);
  const [dataSource, setdataSource] = useState([]);

  const GettingColleges = async () => {
    let res = await axios.get("https://brightbeginning.info/api/admin/listofCourse");
    if (res.status === 200) {
      setgetcollege(res.data.ListofCourse);
      setdataSource(res.data.ListofCourse);
    }
  };

  useEffect(() => {
    GettingColleges();
  }, []);

  const formdata = new FormData();

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 6;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(getcollege.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // integrating post method for Query

  const obj = new FormData();
  const [BName, setBName] = useState("");
  const [BPhone, setBPhone] = useState("");
  const [BEmail, setBEmail] = useState("");
  const [BCourse, setBCourse] = useState("");

  const AddBook = async (e) => {
    e.preventDefault();
    obj.set("BName", BName);
    obj.set("BPhone", BPhone);
    obj.set("BEmail", BEmail);
    obj.set("BCourse", BCourse);

    try {
      const config = {
        url: "/admin/AddBook",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          BName: BName,
          BPhone: BPhone,
          BEmail: BEmail,
          BCourse: BCourse,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingBook();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // integrating get method for Intro
  const [getBook, setgetBook] = useState([]);
  const GettingBook = async () => {
    const res = await axios.get("https://brightbeginning.info/api/admin/listofBook");
    if (res.status === 200) {
      setgetBook(res.data.listofBook);
    }
  };
  useEffect(() => {
    GettingBook();
  }, []);

  const user = JSON.parse(sessionStorage.getItem("user"));
  // if (!user){
  //   return alert("Please Login")
  // }
  // integrating post method for BookCourse

  const obj1 = new FormData();
  const [BcName, setBcName] = useState("");
  const [BcEmail, setBcEmail] = useState("");
  const [BcPhone, setBcPhone] = useState("");
  const [BcCourse, setBcCourse] = useState("");

  const AddBookCourse = async (item) => {
    obj.set("BcName", BcName);
    obj.set("BcEmail", BcEmail);
    obj.set("BcPhone", BcPhone);
    obj.set("BcCourse", BcCourse);
    if (!user){
      alert ("Please Login")
      window.location.assign("/login")
      return;
    }

    try {
      const config = {
        url: "/admin/AddBookCourse",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          BcName: user?.Name,
          BcEmail: user?.Email,
          BcPhone: user?.Number,
          BcCourse: item?.Course,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          // GettingBookCourse();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };

  return (
    <>
      <section
        className="banner nft"
        style={{
          backgroundImage: 'url("/contact.webp")',
          height: "270px",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <section className="mask">
          <div class="container">
            <div class="row">
              <div class="col-md-12 pt-5">
                <h1 class="text-center text-white  ugr">Courses</h1>
                <div class="breadcrumb-bar">
                  <ul class="breadcrumb text-center">
                    <li>Home </li>
                    <IoIosArrowForward className="text-white" />
                    <li>Courses</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div class="search-category">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <form action="#" method="post">
                <div class="form-container">
                  <div class="box-select">
                   
                    <div className="select large">
                      <input
                        className="search"
                        placeholder="Search Courses"
                        onChange={handleFilter}
                        style={{
                          width: "100%",
                          height: "3rem",
                          padding: "2px 17px",
                        }}
                      />
                    </div>
                  </div>
                  <button type="button">Search Course</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="course-area bg-white section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-title-wrapper">
                <div class="section-title">
                  <h3>POPULAR COURSES</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">

            {search.length > 0
              ? tableFilter
                  .slice(pagesVisited, pagesVisited + usersPerPage)
                  .map((item) => {
                    return (
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="single-item mb-50">
                          <div class="single-item-image overlay-effect">
                            <a href={`/courses/${item._id}`}>
                              <img
                                src={`https://brightbeginning.info/Course/${item.CoursePhoto}`}
                                alt=""
                                style={{ height: "240px" }}
                              />
                            </a>
                            <div class="courses-hover-info">
                              <div class="courses-hover-action">
                                <div class="courses-hover-thumb">
                                  <img
                                    className="roundedCircle"
                                    src={`https://brightbeginning.info/Course/${item?.ProfessorImage}`}
                                    alt="small images"
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "50%",
                                      padding: "18% 18%",
                                    }}
                                  />
                                </div>
                                <h4>
                                  <a href="#">{item.ProfessorName}</a>
                                </h4>
                                <span class="crs-separator">/</span>
                                <p>{item.ProfessorDesignation}</p>
                              </div>
                            </div>
                          </div>

                          <div class="single-item-text">
                            <h4>
                              <Link
                                to={`/courses/${item._id}`}
                                state={{ item: item }}
                              >
                                {item.Course}
                              </Link>
                            </h4>
                            <p>{item.CourseDis.slice(0, 107)} ...</p>
                            <div class="single-item-content">
                              <div class="single-item-comment-view">
                                <span>
                                  <FaRupeeSign class="zmdi zmdi-accounts" />
                                  {item.Fee}
                                </span>
                              </div>
                              <div class="single-item-rating">
                                <div className="single-latest">
                                  <div className="single-latest">
                                    <Link to="#">
                                      <a
                                        class="button-default"
                                        onClick={() => {
                                          AddBookCourse(item);
                                        }}
                                      >
                                        Book Now
                                      </a>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
              : dataSource
                  .slice(pagesVisited, pagesVisited + usersPerPage)
                  .map((item) => {
                    return (
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="single-item mb-50">
                          <div class="single-item-image overlay-effect">
                            <a href={`/courses/${item._id}`}>
                              <img
                                src={`https://brightbeginning.info/Course/${item.CoursePhoto}`}
                                alt=""
                                style={{ height: "240px" }}
                              />
                            </a>
                            <div class="courses-hover-info">
                              <div class="courses-hover-action">
                                <div class="courses-hover-thumb">
                                  <img
                                    className="roundedCircle"
                                    src={`https://brightbeginning.info/Course/${item?.ProfessorImage}`}
                                    alt="small images"
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "50%",
                                      padding: "18% 18%",
                                    }}
                                  />
                                </div>
                                <h4>
                                  <a href="#">{item.ProfessorName}</a>
                                </h4>
                                <span class="crs-separator">/</span>
                                <p>{item.ProfessorDesignation}</p>
                              </div>
                            </div>
                          </div>

                          <div class="single-item-text">
                            <h4>
                              <Link
                                to={`/courses/${item._id}`}
                                state={{ item: item }}
                              >
                                {item.Course}
                              </Link>
                            </h4>
                            <p>{item.CourseDis.slice(0, 107)} ...</p>
                            <div class="single-item-content">
                              <div class="single-item-comment-view">
                                <span>
                                  <FaRupeeSign class="zmdi zmdi-accounts" />
                                  {item.Fee}
                                </span>
                              </div>
                              <div class="single-item-rating">
                                <div className="single-latest">
                                  <div className="single-latest">
                                    <Link to={user ? `` : "/login"}>
                                      <a
                                        class="button-default"
                                        onClick={() => {
                                          AddBookCourse(item);
                                        }}
                                      >
                                        Book Now
                                      </a>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
          </div>

          {/* <div class="row">
                            <div class="col-md-12">
                                <div class="pagination-content number">
                                    <ul class="pagination">
                                        <li><a href="#"><MdOutlineKeyboardArrowLeft class="zmdi zmdi-chevron-left"/></a></li>
                                        <li><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li class="current"><a href="#"><MdOutlineKeyboardArrowRight class="zmdi zmdi-chevron-right"/></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        activeClassName={"paginationActive"}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h3>Register Now</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="contact-form">
            <div class="row">
              <div class="col-12">
                <div class="contact-form-style mb-20">
                  <input
                    placeholder="Name*"
                    type="text"
                    onChange={(e) => setBName(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="contact-form-style mb-20">
                  <input
                    placeholder="Phone*"
                    type="text"
                    onChange={(e) => setBPhone(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="contact-form-style mb-20">
                  <input
                    placeholder="Email*"
                    type="text"
                    onChange={(e) => setBEmail(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="contact-form-style">
                  <textarea
                    placeholder="Type your Course details.."
                    onChange={(e) => setBCourse(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddBook}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Courses;
