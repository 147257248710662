import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";

function SubGallery() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // integrating post method for College
  const formdata = new FormData();
  const [SgPhoto, setSgPhoto] = useState("");
  const [SgTitle, setSgTitle] = useState("");

  const AddSubGallery = async () => {
    formdata.set("SgPhoto", SgPhoto);
    formdata.set("SgTitle", SgTitle);

    try {
      const config = {
        url: "/admin/AddSubGallery",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          //   GettingGallery();
          GettingSubGallery();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };
  // integrating get method for Intro
  const [getGallery, setgetGallery] = useState([]);
  const GettingGallery = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/ListofGallery"
    );
    if (res.status === 200) {
      setgetGallery(res.data.ListofGallery);
    }
  };
  useEffect(() => {
    GettingGallery();
  }, []);

  // integrating get method for Intro
  const [getSubGallery, setgetSubGallery] = useState([]);
  const GettingSubGallery = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/ListofSubGallery"
    );
    if (res.status === 200) {
      setgetSubGallery(res.data.ListofSubGallery);
    }
  };
  useEffect(() => {
    GettingSubGallery();
  }, []);

  // delete method for Principal

  const [showdelete, setShowdelete] = useState(false);
  const [iddelete, setiddelete] = useState("");

  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (data) => {
    setShowdelete(true);
    setiddelete(data._id);
  };

  const deleteSubGallery = async () => {
    try {
      const config = {
        url: "/admin/deleteSubGallery",
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
        data: { CourseId: iddelete },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          handleClosedelete();
          GettingGallery();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  const [edit, setedit] = useState("");
  const EditSubGallerys = async (e) => {
    e.preventDefault();
    //  alert("hii");
    try {
      if (!SgTitle) {
        return alert("Please Add Category");
      }
      if (!SgPhoto) {
        return alert("Please Add Image");
      }

      const config = {
        url: "/admin/Updatesubgallery",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          id: edit,
          SgPhoto: SgPhoto,
          SgTitle: SgTitle,
        },
      };

      await axios(config).then(function (res) {
        //  alert("ji")
        if (res.status === 200) {
          alert("Successfull Updated");
          handleClose1();
          GettingSubGallery();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add
      </Button>
      <br />
      <br />

      <section className="container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Event Title</th>
              <th>Main Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getSubGallery.map((data, index) => {
              return (
                <tr>
                  <td style={{textAlign:"left"}}>{++index}</td>
                  <td style={{textAlign:"left"}}>{data.SgTitle}</td>
                  <td style={{textAlign:"left"}}>
                    <img
                      className="coll-image"
                      src={`https://brightbeginning.info/Gallery/${data?.SgPhoto}`}
                    />
                  </td>
                  <td style={{textAlign:"left"}}>
                    <Button
                      variant="danger"
                      className="block-btn mx-2"
                      onClick={() => {
                        handleShowdelete(data);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        handleShow1();
                        setedit(data?._id);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>

      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3>Add Sub Gallery</h3>
        </Modal.Header>
        <Modal.Body>
          <br />

          <Form.Label style={{ fontWeight: "600", marginTop: "-30px" }}>
            Category
          </Form.Label>

          <Form.Select
            onChange={(e) => setSgTitle(e.target.value)}
            aria-label="Default select example"
          >
            <option>Open this select menu</option>
            {getGallery.map((data, index) => {
              return <option value={data.GTitle}>{data.GTitle}</option>;
            })}
          </Form.Select>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>
              Main Image
            </Form.Label>
            <Form.Control
              className="mt-3"
              type="file"
              onChange={(e) => setSgPhoto(e.target.files[0])}
            />
          </Form.Group>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={AddSubGallery}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <Modal show={showdelete} onHide={handleClosedelete}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteSubGallery();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <h3>Update Sub Gallery</h3>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Form.Label style={{ fontWeight: "600", marginTop: "-30px" }}>
            Category
          </Form.Label>

          <Form.Select
            onChange={(e) => setSgTitle(e.target.value)}
            aria-label="Default select example"
          >
            <option>Open this select menu</option>
            {getGallery.map((data, index) => {
              return <option value={data.GTitle}>{data.GTitle}</option>;
            })}
          </Form.Select>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: "600", marginTop: "-28px" }}>
              Main Image
            </Form.Label>
            <Form.Control
              className="mt-3"
              type="file"
              onChange={(e) => setSgPhoto(e.target.files[0])}
            />
          </Form.Group>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">Cancel</Button>
          <Button variant="danger" onClick={EditSubGallerys}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubGallery;
