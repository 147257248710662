import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import "./AdminStudent.css";

const StudentReg = () => {
  // integrating get method for Intro
  const [getReg,setgetReg] = useState([])
  const GettingReg= async()=>{
    const res = await axios.get("https://brightbeginning.info/api/user/allstudent");
    if(res.status === 200){
      setgetReg(res.data.all)
    }
  }
  useEffect(()=>{
    GettingReg()
  },[])

  return (
    <>
      <button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
      >
        Student Registered List
      </button>
      <br />
      <br />

      <table id="table" striped bordered hover size="xl">
        <thead>
          <th id="th">Sl No</th>
          <th>Name</th>
          <th>Number</th>
          <th>Email</th>
          {/* <th> Course</th> */}
        </thead>
        <tbody>
        {getReg?.map((data, index) => {
            return ( 
              <tr>
              <td style={{textAlign:"left"}}>
                {++index}
              </td>
              <td style={{textAlign:"left"}}>
              {data.Name}
              </td>
              <td style={{textAlign:"left"}}>
                {data.Number}
              </td>
              <td style={{textAlign:"left"}}>
                {data.Email}
              </td>
              </tr>
          )})}
        </tbody>
      </table>
    </>
  );
};

export default StudentReg;
