import React from 'react';
import { FaPowerOff } from "react-icons/fa";
import logo from "../Pics/Logo.jpeg"
import "../Adminheader/Adminheader.css"


const Adminheader = () => {

  const logout = ()=>{
    sessionStorage.removeItem("admin")
    window.location.assign("/admin")
  }

  return (
    <>
    <div>



<nav
        className="border-bottom navbar navbar-expaAdminheadernd-lg" 
        // style={{ marginRight: "-10px" }}
      >
        <span className="navbar-brand">
          <div className="d-flex">
            <div>
              <a href="">
               
              <img
                  src="/brightbeginning1.jpg"
                  alt="logo"
                  style={{ width: "173px", height: "135px" }}
       
                  className="d-inline-block align-top"
                 
                />
              </a>
            </div>
            <div className="align-self-end">
              <small className="textOrange"></small>
            </div>
          </div>
        </span>
        <button
          aria-controls="basic-navbar-nav"
          type="button"
          aria-label="Toggle navigation"
          className="navbar-toggler collapsed"
        >
          {/* <span className="navbar-toggler-icon"></span> */}
        </button>

        <div className="navbar-collapse collapse" id="basic-navbar-nav">
          <FaPowerOff
            style={{ marginLeft: "1050px", fontSize: "18px" }}
          />
        </div>

        <div class="dropdownheader">
          <FaPowerOff style={{ marginRight: "250px", fontSize: "18px" }} />
          <div class="dropdown-contentheader">
            <a style={{cursor:'pointer'}}
            onClick={logout}
            >Log Out</a>
          </div>
        </div>
      </nav>
    
    </div>

    </>
  )
}

export default Adminheader