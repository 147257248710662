import React from 'react';

import Form from 'react-bootstrap/Form';
import useDarkMode from 'use-dark-mode';
import './toggle.css';

const DarkModeToggle = ( ) => {
  const darkMode = useDarkMode("custom-switch");
  localStorage.setItem('darkMode', false);
//   if (localStorage.getItem('darkMode') === 'true') {
//     document.getElementById('main-page');


  return (
    <div className="dark-mode-toggle">
      <span className="toggle-control">
    <Form.Check 
        type="switch"
        id="custom-switch"
        onChange={darkMode.toggle}
      />
  </span>
    </div>
  );
};

export default DarkModeToggle;
