import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function CoursesDetails() {

  const location = useLocation()
  const item = location.state
  const {id}=useParams()
  const [course,setCourse]=useState({});
  const getCourse= async ()=>{
    try{
      let res =await axios.get(`https://brightbeginning.info/api/admin/getCourseById/`+ id);
      if(res.status===200){
        setCourse(res.data.success)
      }
    }catch(err){
      console.log(err);
    }
  }
  let history=useNavigate()
  useEffect(()=>{
    getCourse()
  },[])
  // console.log("Course===>",item)
  return (
    <>
    {/* {course.map((data)=> */}
    <>
    <br/>
      <section className="container">
        <h3 className="an">{course.Course}</h3>

        
        <section className="row">
          <div className="col-md">
          
           
            <br />

          
            <h6 className="tnm">
              {course.CourseDis}
            </h6>
            <br />
      <section className="container">
        <br />
        <Table striped bordered hover>
          <thead>
            <tr style={{background:"rgb(246 195 25)",color:"white"}}>
              <th className="ant" colSpan={2}>Course Details</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="t1">Course Name</td>
              <td className="t2">{course.Course}</td>
            </tr>
            <tr>
              <td className="t1">Course duration</td>
              <td className="t2">{course.Duration}</td>
            </tr>

            <tr >
              <td className="t1">Entrance Exam</td>
              <td className="t2">{course.EntranceExam}</td>
            </tr>

            <tr >
              <td className="t1">Total Subjects</td>
              <td className="t2">{course.TotalSubject}</td>
            </tr>
            <tr >
              <td className="t1">Average Fees</td>
              <td className="t2">{course.Fee}</td>
            </tr>
          </tbody>
        </Table>
      </section>
            <br />

           
          </div>
        </section>
      </section>
      <br />
      
      </>
      {/* )} */}
    </>
  );
}

export default CoursesDetails;