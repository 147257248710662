import React from "react";
import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useEffect } from "react";

function Contact() {
  const [show, setShow] = useState(false);
  // const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => setShow1(true);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    // setDelete(data);
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // integrating post method for Footer

  const obj = new FormData();
  const [text, settext] = useState("");
  const [address, setaddress] = useState("");

  const AddContact = async (e) => {
    e.preventDefault();
    obj.set("text", text);
    obj.set("address", address);

    try {
      const config = {
        url: "/admin/addcontact",
        method: "post",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          text: text,
          address: address,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
          GettingContact();
        } else {
          alert("error in adding");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };
  // integrating get method for Intro
  const [getcontact, setgetcontact] = useState([]);
  const GettingContact = async () => {
    const res = await axios.get("https://brightbeginning.info/api/admin/allcontact");
    if (res.status === 200) {
      setgetcontact(res.data.success);
    }
  };
  useEffect(() => {
    GettingContact();
  }, []);

  // delete method for Principal

  const [showdelete, setShowdelete] = useState(false);
  const [iddelete, setiddelete] = useState("");

  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (data) => {
    setShowdelete(true);
    setiddelete(data._id);
  };

  const deleteContact = async () => {
    try {
      const config = {
        url: "/admin/delete/" + iddelete,
        method: "delete",
        baseURL: "https://brightbeginning.info/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert("Successfully Deleted");
          handleClosedelete();
          GettingContact();
        }
      });
    } catch (error) {
      alert(error);
    }
  };

  const [edit, setedit] = useState();
  const [faderss, setfaddress] = useState();
  const [fcontact, setfcontact] = useState();
  const EditContact = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/update",
        method: "put",
        baseURL: "https://brightbeginning.info/api",
        header: "content-type : appliction-json",
        data: {
          id: edit,
          text: faderss,
          address: fcontact,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert(res.data.success);
          console.log("success");
          handleClose1();
          GettingContact();
        } else {
          alert("error in edit");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "rgb(2 174 239)",
          padding: "10px",
          color: "white",
          border: "1px solid white",
          fontWeight: "600",
          marginLeft: "10%",
        }}
        onClick={handleShow}
      >
        Add
      </Button>
      <br />
      <br />

      <section className="container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Text</th>
              <th>address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getcontact.map((data, index) => {
              return (
                <tr>
                  <td>{++index}</td>
                  <td style={{ textAlign: "left" }}>{data.text}</td>
                  <td style={{ textAlign: "left" }}>
                    <h6>{data.address}</h6>
                  </td>

                  <td>
                    <Button
                      className="mx-2 mb-2"
                      variant="danger"
                      onClick={() => handleShowdelete(data)}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        handleShow1();
                        setedit(data._id);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>

      {/* modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Add Contact</h3>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Text</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: "100px" }}
              onChange={(e) => settext(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setaddress(e.target.value)}
            />
          </Form.Group>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={AddContact}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <Modal show={showdelete} onHide={handleClosedelete}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure you want to delete!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteContact();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* edit */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Edit Contact</h3>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Text</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: "100px" }}
              value={faderss}
              onChange={(e) => setfaddress(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              value={fcontact}
              onChange={(e) => setfcontact(e.target.value)}
            />
          </Form.Group>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="danger" onClick={EditContact}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Contact;
