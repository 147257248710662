import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import "./Gallery.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

function Gallery() {
  // integrating get method for Gallery
  const [getGallery, setgetGallery] = useState([]);
  const GettingGallery = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/ListofGallery"
    );
    if (res.status === 200) {
      setgetGallery(res.data.ListofGallery);
    }
  };
  useEffect(() => {
    GettingGallery();
  }, []);

  return (
    <>
      <section
        className="banner nft"
        style={{
          backgroundImage: 'url("/contact.webp")',
          height: "270px",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <section className="mask">
          <div class="container">
            <div class="row">
              <div class="col-md-12 pt-5">
                <h1 class="text-center text-white ugr">Gallery</h1>
                <div class="breadcrumb-bar">
                  <ul class="breadcrumb text-center">
                    <li>Home </li>
                    <IoIosArrowForward className="text-white" />
                    <li>Gallery</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="container mt-5">
        <div className="row">
          {getGallery.map((data, index) => {
            return (
              <div className="col-md-4">
                <div className=" atf-single-feature mb-3 ">
                  <Link to="/galleryallphotos" state={{ data: data }}>
                    <img
                      className="g-image"
                      src={`https://brightbeginning.info/Gallery/${data?.GPhoto}`}
                      style={{ width: "100%" }}
                    />
                    <h5
                      className="text-center"
                      style={{ paddingTop: " 16px", color: "white" }}
                    >
                      {data.GTitle}
                    </h5>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Gallery;
