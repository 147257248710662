import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaStamp } from "react-icons/fa";
import {GiCarrier, GiTeacher, GiWhiteBook} from "react-icons/gi"
import {ImLibrary} from 'react-icons/im'
import { MdDesignServices } from "react-icons/md";
import { Link } from "react-router-dom";
import '../Styles/itservice.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function ITService() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="affect atf-section-padding">
        <Container>
          <div className="course_hero-0">
            <Row>
              <Col lg={7} md={7}>
                <div className="atf-section-title">
                  <h2 className="atf-title-color border-left ps-3">
                    OUR <span>LATEST</span> SERVICE
                  </h2>
                </div>
              </Col>
              <Col lg={5} md={5}>
                <div className="atf-hero-btn text-end" onClick={handleShow}>
                  <Link to="" class="atf-main-btn" >
                    <span>Enquire Now</span>
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={4} md={4} sm={4}>
            
                <div className="serviceBox "data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500">
                  <div className="service-icon mb-3">
                    <span>
                    <FaStamp/>
                    </span>
                  </div>

                  <h3 className="title mb-3">Trending &amp; Certified</h3>

                  <p className="description">
                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                    Qui quaerat fugit quas sequi dolore quisquam illum.
                  </p>
                </div>
               
              </Col>
              <Col lg={4} md={4} sm={4}>
              <div className="serviceBox" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1500">
                  <div className="service-icon mb-3">
                    <span>
                       <GiWhiteBook/>
                    </span>
                  </div>

                  <h3 className="title mb-3">Online &amp; E-Books</h3>

                  <p className="description">
                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                    Qui quaerat fugit quas sequi dolore quisquam illum.
                  </p>
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <div className="serviceBox"data-aos="fade-left" data-aos-delay="100" data-aos-duration="1500">
                  <div className="service-icon mb-3">
                    <span>
                   <ImLibrary/>
                    </span>
                  </div>

                  <h3 className="title mb-3">Liberary &amp; Guarant</h3>

                  <p className="description">
                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                    Qui quaerat fugit quas sequi dolore quisquam illum.
                  </p>
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <div className="serviceBox"data-aos="fade-left" data-aos-delay="100" data-aos-duration="1500">
                  <div className="service-icon mb-3">
                    <span>
                     <GiTeacher/>
                    </span>
                  </div>

                  <h3 className="title mb-3">Teachers &amp; Career</h3>

                  <p className="description">
                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                    Qui quaerat fugit quas sequi dolore quisquam illum.
                  </p>
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <div className="serviceBox" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
                  <div className="service-icon mb-3">
                    <span>
                     <MdDesignServices/>
                    </span>
                  </div>

                  <h3 className="title mb-3">Online &amp; Course</h3>

                  <p className="description">
                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                    Qui quaerat fugit quas sequi dolore quisquam illum.
                  </p>
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
              <div className="serviceBox" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500">
                  <div className="service-icon mb-3">
                    <span>
                     <GiCarrier/>
                    </span>
                  </div>

                  <h3 className="title mb-3">Courses &amp; Career </h3>

                  <p className="description">
                    Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                    Qui quaerat fugit quas sequi dolore quisquam illum.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> <h3> Enquire Now</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
    
            <form id="contact-form" action="mail.php" method="post">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="contact-form-style mb-20">
                                                    <input name="name" placeholder="Name*" type="text"/>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="contact-form-style mb-20">
                                                    <input name="phone" placeholder="Phone*" type="text"/>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="contact-form-style mb-20">
                                                    <input name="email" placeholder="Email*" type="email"/>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="contact-form-style">
                                                    <textarea name="message" placeholder="Type your Message here.."></textarea>
                                               </div>
                                            </div>
                                        </div>
                                    </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Send Query
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ITService;
