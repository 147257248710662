import React, { useEffect, useState } from "react";
import { Image } from "antd";
import "./Gallery.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function GalleryAllPhotos() {
  const location = useLocation();
  const { data } = location.state;
  // console.log("check it", data);

  // integrating get method for Intro
  const [getSubGallery, setgetSubGallery] = useState([]);
  const GettingSubGallery = async () => {
    const res = await axios.get(
      "https://brightbeginning.info/api/admin/ListofSubGallery"
    );
    if (res.status === 200) {
      setgetSubGallery(res.data.ListofSubGallery);
    }
  };
  useEffect(() => {
    GettingSubGallery();
  }, []);

  // console.log("data==>",getSubGallery.map((data, index) =>data.SgTitle));

  return (
    <>
     <section
        className="banner nft"
        style={{
          backgroundImage: 'url("/contact.webp")',
          height: "270px",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <section className="mask">
          <div class="container">
            <div class="row">
              <div class="col-md-12 pt-5">
                <h1 class="text-center text-white ugr">Sub Gallery</h1>
                <div class="breadcrumb-bar">
                  <ul class="breadcrumb text-center">
                    <li>Home </li>
                    <IoIosArrowForward className="text-white" />
                    <li>Gallery</li>
                    <IoIosArrowForward className="text-white" />
                    <li>Sub Gallery</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="container mt-5">
        <div className="">
        <h1 class="text-center" style={{fontSize:"35px",fontWeight:"600"}}>{data.GTitle}</h1>
        </div>
        <section className="row">
          {/* {getSubGallery.filter((data)=>(data.SgTitle)==(data.GTitle)).map((data) => {
            return (  */}
          {getSubGallery
            .filter((ele) => ele.SgTitle === data.GTitle)
            .map((item) => {
              return (
                <div className="col-md-3">
                  <div className="pitem mb-3">
                    <Image
                      className="all-images"
                    
                      height={200}
                      src={`https://brightbeginning.info/Gallery/${item?.SgPhoto}`}
                    />
                  </div>
                </div>
              );
            })}
        </section>
      </section>
    </>
  );
}

export default GalleryAllPhotos;
